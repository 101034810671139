import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { setHours, setMinutes } from 'date-fns';


function OneWayPage() {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [person, setPerson] = useState('');
  const [pickupDate, setPickupDate] = useState('');
  const [pickupTime, setPickupTime] = useState('');
  const [formattedPickupTime, setFormattedPickupTime] = useState('');
  const [loadMap, setLoadMap] = useState(false);
  const [fromPlace, setFromPlace] = useState(null);
  const [toPlace, setToPlace] = useState(null);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const fromInputRef = useRef(null);
  const toInputRef = useRef(null);
  const navigate = useNavigate();
  // const [startDate, setStartDate] = useState('');

  const editFlag = false;
  const [startDate, setStartDate] = React.useState('');

  const initialMinTime = editFlag
    ? setHours(setMinutes(new Date(), 0), 18)
    : setHours(setMinutes(new Date(), 0), 0);

  const [minTime, setMinTime] = React.useState(initialMinTime);
  const [maxTime, setMaxTime] = React.useState(
    setHours(setMinutes(new Date(), 59), 23)
  );



  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      initPlaceAPI();
    } else {
      loadGoogleMapScript(() => {
        setLoadMap(true);
        initPlaceAPI();
      });
    }
  }, []);

  const GOOGLE_MAP_API_KEY = 'AIzaSyC34N-enppR8BKspnOpDkV6in2fGsz4drI';

  // Load Google Maps JavaScript API
  const loadGoogleMapScript = (callback) => {
    const googleMapScript = document.createElement('script');
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
    document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener('load', callback);
  };

  // Initialize the Google Places Autocomplete
  const initPlaceAPI = () => {
    const autocompleteFrom = new window.google.maps.places.Autocomplete(fromInputRef.current, {
      componentRestrictions: { country: 'in' }, // Restrict search to India
    });
    autocompleteFrom.addListener('place_changed', () => {
      const place = autocompleteFrom.getPlace();
      setFrom(place.formatted_address);
      setFromPlace({
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        name: place.name
      });
    });

    const autocompleteTo = new window.google.maps.places.Autocomplete(toInputRef.current, {
      componentRestrictions: { country: 'IN' }, // Restrict search to India
    });
    autocompleteTo.addListener('place_changed', () => {
      const place = autocompleteTo.getPlace();
      setTo(place.formatted_address);
      setToPlace({
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        name: place.name
      });
    });
  };

  const today = new Date().toISOString().split('T')[0];

  const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};

    // Basic Validation
    if (!from) newErrors.from = 'Pickup location is required.';
    if (!to) newErrors.to = 'Destination location is required.';
    if (!person) newErrors.person = 'Number of persons is required.';
    if (!pickupDate) newErrors.pickupDate = 'Pickup date is required.';
    if (!startDate) newErrors.pickupTime = 'Pickup time is required.';

    // If there are validation errors, set errors and stop submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Clear errors if validation passes
    setErrors({});

    // Convert the selected time to the desired format (12-hour)
    const formattedTime = convertTo12HourFormat(
      `${startDate.getHours().toString().padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')}`
    );

    const pickupTimeValue = `${startDate.getHours().toString().padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')}`;
    setPickupTime(pickupTimeValue);
    setFormattedPickupTime(formattedTime);

    const formData = {
      from,
      to,
      person,
      pickupDate,
      pickupTime: pickupTimeValue,
      formattedPickupTime: formattedTime,
      fromPlace,
      toPlace
    };

    setData([formData]);
    console.log('formData =>', formData);

    const queryString = new URLSearchParams({
      type: 'onewayTrip',
      from: encodeURIComponent(from),
      to: encodeURIComponent(to),
      person: encodeURIComponent(person),
      pickupDate: encodeURIComponent(pickupDate),
      formattedPickupTime: encodeURIComponent(formattedTime),
      pickupTime: encodeURIComponent(pickupTimeValue),
      fromPlace: encodeURIComponent(JSON.stringify(fromPlace)),
      toPlace: encodeURIComponent(JSON.stringify(toPlace))
    }).toString();

    navigate(`/onewaydetails?${queryString}`);
  };

  return (
    <form onSubmit={handleSubmit} className='h-auto'>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-8 px-0 mt-12 mb-5 rounded-[16px]">
        <div className="relative">
          <input
            type="text"
            placeholder="Start typing city - e.g. Bangalore"
            ref={fromInputRef}
            value={from}
            onChange={(e) => setFrom(e.target.value)}
            className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans'] font-normalFont"
            style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
          />
          <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px] uppercase ">Pickup From</label>
          {errors.from && <p className="text-red-500 text-xs mt-1">{errors.from}</p>}
        </div>

        <div className="relative">
          <input
            type="text"
            placeholder="Start typing city - e.g. Mysore"
            ref={toInputRef}
            value={to}
            onChange={(e) => setTo(e.target.value)}
            className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans'] font-normalFont"
            style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
          />
          <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px] uppercase ">To Destination</label>
          {errors.to && <p className="text-red-500 text-xs mt-1">{errors.to}</p>}
        </div>

        <div className="relative">
          <input
            type="number"
            placeholder="How many persons...?"
            onChange={(e) => setPerson(e.target.value)}
            min="1"
            className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans'] font-normalFont"
            style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
          />
          <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px] uppercase">Person</label>
          {errors.person && <p className="text-red-500 text-xs mt-1">{errors.person}</p>}
        </div>

        {/* <div className="relative">
          <input
            type="date"
            value={pickupDate}
            onChange={(e) => setPickupDate(e.target.value)}
            className="border-b font-['Open_Sans'] p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
            style={{ fontSize: '13px' }}
            min={today}
          />
          <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">Pickup Date</label>
          {errors.pickupDate && <p className="text-red-500 text-xs mt-1">{errors.pickupDate}</p>}
        </div> */}

        <div className="relative">

          <DatePicker
            selected={pickupDate}
            onChange={(date) => setPickupDate(date)} // Set the selected date
            placeholderText="Select a Pickup Date" // Placeholder text for the input field
            dateFormat="MMMM d, yyyy" // Date format (without time)
            className="border-b text-[13px] p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-normalFont"
            minDate={new Date()} // Disable past dates
          />
          <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px] uppercase">Pickup Date</label>
          {errors.pickupDate && <p className="text-red-500 text-xs mt-1">{errors.pickupDate}</p>}
        </div>


        <div className="relative">

          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              if (date.getDate() === new Date().getDate()) {
                setMinTime(setHours(setMinutes(new Date(), 0), 18));
              } else {
                setMinTime(setHours(setMinutes(new Date(), 0), 0));
              }
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            placeholderText="Select Pickup Time" // Added placeholder text
            dateFormat="h:mm aa" // Display format
            className="border-b text-[13px] p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-normalFont"
          />
          <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px] uppercase">Pickup Time</label>
          {errors.pickupTime && <p className="text-red-500 text-xs mt-1">{errors.pickupTime}</p>}
        </div>


        {/* <input
            type="time"
            value={pickupTime}
            onChange={(e) => setPickupTime(e.target.value)}
            className="border-b p-3 font-['Open_Sans'] w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
            style={{ fontSize: '12px' }}
          /> */}

      </div>

      <div className="flex justify-center mt-6">
        <button type="submit" className="bg-customBlue text-white w-[100%] sm:w-[30%] py-2 text-md rounded-md font-[700] mt-[-5px] font-nunito">
          Explore Cabs
        </button>
      </div>
    </form>
  );
}

export default OneWayPage;
