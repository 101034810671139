import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaLocationDot } from "react-icons/fa6";

const PlaceImages = () => {
  const [places, setPlaces] = useState([]);
  const [activePlace, setActivePlace] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://bhadegadiapi.taxiwalataxi.in/api/v1/getmemoryimg')
      .then(response => {
        const fetchedPlaces = response.data.data;
        setPlaces(fetchedPlaces);
        if (fetchedPlaces.length > 0) {
          setActivePlace(fetchedPlaces[0]._id);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  const handleButtonClick = (placeId) => {
    setActivePlace(placeId);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="text-lg font-semibold">Loading...</div>
      </div>
    );
  }
  return (
    <div className="container mx-auto pb-10 ">
        <center className='mb-10'>
            <h1 className='font-[600] text-[28px]  mt-20 text-customfontBlack'>
                Explore Most Popular Memories </h1>
                <p className='font-[550] text-[20px] text-customfontBlack'>Fun and Our Tour Memories</p>
            </center>

            <div className="mb-8">

            <div className="mb-8">
 
  <div className="flex flex-wrap justify-center gap-4 mb-6">
    {/* {places.map(place => (
      <button
        key={place._id}
        onClick={() => handleButtonClick(place._id)}
        className={`relative px-6 py-3 font-semibold text-md rounded-lg shadow-lg transition-transform transform hover:shadow-xl focus:outline-none ${activePlace === place._id ? 'bg-black text-customBlue' : 'bg-customBlue text-white '} hover:bg-black`}
      >
        <span className="flex items-center">
          <FaLocationDot  />
          <p className='ml-2  capitalize'> {place.placeName}</p>
        </span>
      </button>
    ))} */}
     {places.map(place => (
      <button
        key={place._id}
        onClick={() => handleButtonClick(place._id)}
        className={`relative px-6 py-3 font-semibold text-md rounded-lg shadow-lg transition-transform transform hover:shadow-xl focus:outline-none ${activePlace === place._id ? 'bg-white border border-customBlue text-customBlue' : 'bg-white text-black border border-black '}`}
      >
        <span className="flex items-center">
          <FaLocationDot  />
          <p className='ml-2  capitalize'> {place.placeName}</p>
        </span>
      </button>
    ))}
  </div>

  {activePlace && (
    <div className="flex justify-center items-center">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 max-w-[1200px]">
        {places.find(place => place._id === activePlace).memoryImgs.map((image, index) => (
          <div key={index} className="relative w-full h-48 overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105">
            <img
              src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${image}`}
              alt='Place Image'
              className="w-full h-full object-cover transition duration-300 transform hover:scale-110"
            />
            <div className="absolute inset-0 bg-black opacity-0 hover:opacity-40 transition-opacity duration-300 flex items-center justify-center text-white text-lg font-semibold">
           {places.find(place => place._id === activePlace).placeName} 
            </div>
          </div>
        ))}
      </div>
    </div>
  )}
</div>

</div>

    </div>
  );
};

export default PlaceImages;
