import React, { useEffect } from 'react';
import bag from '../../Images/travel-bag.png';
import Roundtrip1 from '../../Images/expert drivers.png';
import Roundtrip2 from '../../Images/safe.png';
import Roundtrip3 from '../../Images/multiple stops.png';
import oneway1 from '../../Images/blog.png';
import oneway2 from '../../Images/lowest fares.png'; 
import oneway3 from '../../Images/All inclusive.png'; 
import localRental1 from '../../Images/Flexible Packages.png'; 
import localRental2 from '../../Images/safe.png'; 
import localRental3 from '../../Images/multiple stops.png'; 
import Airport1 from '../../Images/Reliability Guaranteed.png'; 
import Airport2 from '../../Images/lowest fares.png'; 
import Airport3 from '../../Images/expert drivers.png'; 
import main1 from '../../Images/round-trip.webp';
import main2 from '../../Images/oneway.jpg';
import main3 from '../../Images/LOCAL RENTALS.webp';
import main4 from '../../Images/AIRPORT TRANSFERS.jpg';
import main_image_icon1 from '../../Images/Roundtrip Cabs.png';
import main_image_icon2 from '../../Images/Oneway Drops.png';
import main_image_icon3 from '../../Images/Local Rentals.png';
import main_image_icon4 from '../../Images/Airport Transfers.png';
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos"; // Import AOS library

// Card Component
const Card = ({ img, name, description, icon, imag_icon }) => {

  return (
    <div 
      className="relative bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-[16px] overflow-hidden" // AOS animation attribute
    >
      <img src={img} alt={name} className="w-full h-48 object-cover" srcset={img} sizes="" loading="lazy" />
      
      {/* Positioned image at the bottom right */}
      <img
        src={imag_icon}
        alt="Icon"
        className="absolute top-40 right-3 w-12 h-12 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-full"
        srcset={imag_icon} sizes="" loading="lazy"
      />

      <div className="p-4 mt-1">
        <h3 className="text-lg font-bold mb-3 text-center text-customBlue">{name}</h3>
        <p className="text-customfontBlack font-[500] leading-6 text-xs text-justify">
          {description}
        </p>
        <div className="flex justify-center">
          <div className="flex justify-between items-center w-[100%] max-w-[1200px] gap-x-6">
            {icon.icon_img.map((iconImg, i) => (
              <div key={i} className="text-center mt-3">
                <img 
                  src={iconImg} 
                  alt={icon.icon_name[i]} 
                  className="w-12 h-12 mx-auto mb-1 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-full transition-transform transform hover:scale-110"
                  srcset={iconImg} sizes="" loading="lazy"
                />
                <p className="text-xs text-customfontBlack font-[500] mt-2">
                  {icon.icon_name[i]}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// CardList Component
const CardList = () => {
  const initialize = [
    {
      img: main1,
      name: 'ROUNDTRIP CABS',
      icon_img: main_image_icon1,
      description: 'Enjoy our premium roundtrip services for a luxurious and comfortable drive from your doorstep and back. Our expert chauffeurs ensure a memorable journey with their courteous and professional demeanor. Experience with Hindustan Rental Taxi and make every road trip exceptional.',
      icon: {
        icon_name: ['Expert Chauffeurs', 'Safety Certified', 'Multiple Stops'],
        icon_img: [Roundtrip1, Roundtrip2, Roundtrip3]
      },
    },
    {
      img: main2,
      name: 'ONEWAY DROPS',
      icon_img: main_image_icon2,
      description: 'Our extensive network covers over 15 lakh one-way routes, You can travel to any corner of the country with ease. With us, you only pay for one side of the journey at incredibly low rates. Travel with confidence and comfort, knowing Hindustan Rental Taxi is your reliable travel partner.',
      icon: {
        icon_name: ['15 Lakh Routes', 'Lowest Fares', 'All Inclusive Prices'],
        icon_img: [oneway1, oneway2, oneway3]
      },
    },
    {
      img: main3,
      name: 'LOCAL RENTALS',
      icon_img: main_image_icon3,
      description: 'Book our flexible hourly rental cabs and enjoy chauffeured rides within the city for business meetings, shopping, or any errands. Choose from 4hr, 8hr or 12hr hour packages to suit your needs. Experience the convenience of exploring your city like a local with Hindustan Rental Taxi.',
      icon: {
        icon_name: ['Flexible Packages', 'Cab At Your Disposal', 'Multiple Stops'],
        icon_img: [localRental1, localRental2, localRental3]
      },
    },
    {
      img: main4,
      name: 'AIRPORT TRANSFERS',
      icon_img: main_image_icon4,
      description: 'We care about your flight as much as you do. Our reliable airport transfer services ensure timely pickups and drops across airports nationwide. Book in advance and rest easy, knowing we\'ll take care of the rest. Travel with confidence and experience hassle-free travel with Hindustan Rental Taxi.',
      icon: {
        icon_name: ['Reliability Guaranteed', 'Lowest Fares', 'Courteous Chauffeurs'],
        icon_img: [Airport1, Airport2, Airport3]
      },
    },
  ];

  return (

    <div className='mt-[88px] text-center '>

        <center >
               <h1 className='font-[600] text-[28px] text-customfontBlack'> Great Services for Every Trip </h1>
                <p className='font-[550] text-[20px] text-customfontBlack'>Check Out Our Services.</p>
            </center>
     
      {/* <h1 className='font-[600] text-[25px] font-nunito py-5 text-customfontBlack mb-5'>OUR SERVICES</h1> */}
      <div className="flex justify-center mt-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6 max-w-[1200px] mx-4">
          {initialize.map((data, index) => (
            <Card
              key={index}
              img={data.img}
              imag_icon={data.icon_img}
              name={data.name}
              description={data.description}
              icon={data.icon}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardList;
