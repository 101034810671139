import React,{useEffect} from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing

const RouteList = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, [navigate]);
  return (
    <div className='container mx-auto p-6'>
      <h1 className='text-3xl font-bold mb-6 text-center'>Available Routes</h1>

       {/* surat */}
       <ul className=''>
        <li className='cursor-pointer bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300'>
          <div> 
            <h1 className='text-xl font-semibold mb-4 text-black'>
              One Way Taxi From Surat
            </h1>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2'>
             
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => {
                  navigate('/surat-to-ahm?r=Surat to Ahmedabad');
                  window.scrollTo(0, 0); // Scrolls to the top on click
                }}>
                Surat to Ahmedabad Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-anand?r=Surat to AnandGujarat')}>
                Surat to Anand Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-ankleshwar?r=Surat to Ankleshwar')}>
                Surat to Ankleshwar Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-bharuch?r=Surat to Bharuch')}>
                Surat to Bharuch Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-dahej?r=Surat to dahej')}>
                Surat to Dahej Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-saputara?r=Surat to Saputara')}>
                Surat to Saputara Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-ajmer?r=Surat to Ajmer')}>
                Surat to Ajmer Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-bhiwandi?r=Surat to Bhiwandi')}>
                Surat to Bhiwandi Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-himmatnagar?r=Surat to Himmatnagar')}>
                Surat to Himmatnagar Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-mumbai?r=Surat to MumbaiAirport')}>
                Surat to Mumbai Airport Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-ulhasnagar?r=Surat to Ulhasnagar')}>
               Surat to Ulhasnagar Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-badlapur?r=Surat to Badlapur')}>
                Surat to Badlapur Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-dadar?r=Surat to Dadar')}>
                Surat to Dadar Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-panvel?r=Surat to Panvel')}>
                Surat to Panvel Cab
              </h1>     

              {/* ====================================================== */}



              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-mumbai?r=Surat to Mumbai')}>
                Surat to Mumbai Central Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-kota?r=Surat to Kota')}>
                Surat to Kota Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-amreli?r=Surat to Amreli ')}>
                Surat to Amreli  Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-mumbai?r=Surat to Mumbai ')}>
                Surat to Mumbai  Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-nashik?r=Surat to Nashik ')}>
                Surat to Nashik  Cab
              </h1>        
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-mumbai?r=Surat to Mumbai')}>
                Surat to Navi Mumbai Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-pune?r=Surat to Pune')}>
                Surat to Pune Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-rajkot?r=Surat to Rajkot')}>
                Surat to Rajkot Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-lonavala?r=Surat to Lonavala')}>
                Surat to Lonavala Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-thane?r=Surat to Thane ')}>
                Surat to Thane Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-vadodara?r=Surat to Vadodara')}>
                Surat to Vadodara Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-jodhpur?r=Surat to Jodhpur')}>
                Surat to Jodhpur Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-indore?r=Surat to Indore')}>
                Surat to Indore Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-palitana?r=Surat to Palitana')}>
                Surat to Palitana Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-jaipur?r=Surat to Jaipur')}>
                Surat to Jaipur Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-jamnagar?r=Surat to Jamnagar')}>
                Surat to Jamnagar Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-bhuj?r=Surat to Bhuj')}>
                Surat to Bhuj Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-bhavnagar?r=Surat to Bhavnagar')}>
                Surat to Bhavnagar Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-dwarka?r=Surat to Dwarka')}>
                Surat to Dwarka Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-deolali?r=Surat to Deolali')}>
                Surat to Deolali Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-veraval?r=Surat to Veraval')}>
                Surat to Veraval Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-virarMaharashtra?r=Surat to virarMaharashtra')}>
                Surat to Virar Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-valsad?r=Surat to Valsad')}>
                Surat to Valsad Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-goa?r=Surat to Goa')}>
                Surat to Goa Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-udaipur?r=Surat to Udaipur')}>
                Surat to Udaipur Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-daman?r=Surat to Daman')}>
                Surat to Daman Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-navsari?r=Surat to Navsari')}>
                Surat to Navsari Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-vapi?r=Surat to Vapi')}>
                Surat to Vapi Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-borivali?r=Surat to Borivali')}>
                Surat to Borivali Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-kalyan?r=Surat to Kalyan')}>
                Surat to Kalyan Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-vasai?r=Surat to Vasai')}>
                Surat to Vasai Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-solapur?r=Surat to Solapur')}>
                Surat to Solapur Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-bhopal?r=Surat to Bhopal')}>
                Surat to Bhopal Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-ujjain?r=Surat to Ujjain')}>
                Surat to Ujjain Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-ajmer?r=Surat to Ajmer')}>
                Surat to Ajmer Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-barmer?r=Surat to Barmer')}>
                Surat to Barmer Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-nagpur?r=Surat to Nagpur')}>
                Surat to Nagpur Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-morbi?r=Surat to Morbi')}>
                Surat to Morbi Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-delhi?r=Surat to Delhi')}>
                Surat to Delhi Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-palanpur?r=Surat to Palanpur')}>
                Surat to Palanpur Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-bangalore?r=Surat to Bangalore')}>
                Surat to Bangalore Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-mahabaleshwar?r=Surat to Mahabaleshwar')}>
                Surat to Mahabaleshwar Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-statueofunity?r=Surat to StatueOfUnity')}>
                Surat to Statue Of Unity Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-dhari?r=Surat to Dhari')}>
                Surat to Dhari Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-shirdi?r=Surat to Shirdi')}>
                Surat to Shirdi Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-pratapgarh?r=Surat to Pratapgarh')}>
                Surat to Pratapgarh Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-prayagraj?r=Surat to Prayagraj')}>
                Surat to Prayagraj Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-kanpur?r=Surat to Kanpur')}>
                Surat to Kanpur Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-jhansi?r=Surat to Jhansi')}>
                Surat to Jhansi Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-sultanpur?r=Surat to Sultanpur')}>
                Surat to Sultanpur Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-varanasi?r=Surat to Varanasi')}>
                Surat to Varanasi Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-ayodhya?r=Surat to Ayodhya')}>
                Surat to Ayodhya Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-azamgarh?r=Surat to Azamgarh')}>
                Surat to Azamgarh Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-rajula?r=Surat to Rajula')}>
                Surat to Rajula Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-mumbai?r=Surat to Mumbai Innova Car')}>
                Surat to Mumbai Innova Car
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-mumbai?r=Surat to Mumbai Dzire Car')}>
                Surat to Mumbai Dzire Car
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-mumbai?r=Surat to Mumbai Tempo Travel Car')}>
                Surat to Mumbai Tempo Travel  Car
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-mumbai?r=Surat to Mumbai Force Urbania Car')}>
                Surat to Mumbai Force Urbania Car
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-mumbai?r=Surat to Mumbai Urbania Car')}>
                Surat to Mumbai Urbania Car
              </h1> 
               <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-vasai?r=Surat to Vasai')}>
                Surat to Vasai Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-vansda?r=Surat to Vansda')}>
                Surat to Vansda Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-rajpipla?r=Surat to Rajpipla')}>
                Surat to Rajpipla Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-vyara?r=Surat to Vyara')}>
                Surat to Vyara Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-kheda?r=Surat to Kheda')}>
                Surat to Kheda Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-dhule?r=Surat to Dhule')}>
                Surat to Dhule Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-bilimora?r=Surat to Bilimora')}>
                Surat to Bilimora Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-padra?r=Surat to Padra')}>
                Surat to Padra Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-pavagadh?r=Surat to pavagad')}>
                Surat to Pavagadh Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-chandod?r=Surat to Chandod')}>
                Surat to Chandod Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-mehsana?r=Surat to Mehsana')}>
                Surat to Mehsana Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-patan?r=Surat to Patan')}>
                Surat to Patan Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-visnagar?r=Surat to Visnagar')}>
                Surat to Visnagar Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-keshod?r=Surat to keshod')}>
                Surat to keshod Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-idar?r=Surat to Idar')}>
                Surat to Idar Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-anand?r=Surat to Anand')}>
                Surat to Anand Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-alibag?r=Surat to Alibag')}>
                Surat to Alibag Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-mahabaleshwar?r=Surat to Mahabaleshwar')}>
                Surat to Mahabaleshwar Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-ulhasnagar?r=Surat to Ulhasnagar')}>
                Surat to Ulhasnagar Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-khopoli?r=Surat to Khopoli')}>
                Surat to Khopoli Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-nadiad?r=Surat to Nadiad')}>
                Surat to Nadiad Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-boisar?r=Surat to Boisar')}>
                Surat to Boisar Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-borsad?r=Surat to Borsad')}>
                Surat to Borsad Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-kevadia?r=Surat to Kevadia')}>
                Surat to Kevadia Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-gandevi?r=Surat to Gandevi')}>
                Surat to Gandevi Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-surendranagar?r=Surat to Surendranagar')}>
                Surat to Surendranagar Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-unjha?r=Surat to Unjha')}>
                Surat to Unjha Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-upleta?r=Surat to Upleta')}>
                Surat to Upleta Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-rapar?r=Surat to Rapar')}>
                Surat to Rapar Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-khambhat?r=Surat to Khambhat')}>
                Surat to Khambhat Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-jambusar?r=Surat to Jambusar')}>
                Surat to Jambusar Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-nagpur?r=Surat to Nagpur')}>
                Surat to Nagpur Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-solapur?r=Surat to Solapur')}>
                Surat to Solapur Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-morbi?r=Surat to Morbi')}>
                Surat to Morbi Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-dakor?r=Surat to Dakor')}>
                Surat to Dakor Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-trimbak?r=Surat to Trimbak')}>
                Surat to Trimbak Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-nandurbar?r=Surat to Nandurbar')}>
                Surat to Nandurbar Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-sarangpur?r=Surat to Sarangpur')}>
                Surat to Sarangpur Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-palghar?r=Surat to Palghar')}>
                Surat to Palghar Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-umargam?r=Surat to Umargam')}>
                Surat to Umargam Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-chikhli?r=Surat to chikhli ')}>
                Surat to chikhli  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-ambernath?r=Surat to Ambernath')}>
                Surat to Ambernath Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-una?r=Surat to Una')}>
                Surat to Una Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-sihor?r=Surat to Sihor')}>
                Surat to Sihor Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-palej?r=Surat to Palej')}>
                Surat to Palej Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-petlad?r=Surat to Petlad')}>
                Surat to Petlad Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-akola?r=Surat to Akola')}>
                Surat to Akola Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-deolali?r=Surat to Deolali')}>
                Surat to Deolali Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-valsad?r=Surat to Valsad')}>
                Surat to Valsad Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-halvad?r=Surat to Halvad')}>
                Surat to Halvad Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-bardoli?r=Surat to Bardoli')}>
                Surat to Bardoli Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-hazira?r=Surat to Hazira')}>
                Surat to Hazira Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-dharampur?r=Surat to Dharampur')}>
                Surat to Dharampur Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-anjar?r=Surat to Anjar')}>
                Surat to Anjar Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-jetpur?r=Surat to Jetpur')}>
                Surat to Jetpur Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-amravati?r=Surat to Amravati')}>
                Surat to Amravati Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-hyderabad?r=Surat to Hyderabad')}>
                Surat to Hyderabad Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-wankaner?r=Surat to Wankaner')}>
                Surat to Wankaner Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/surat-to-sikar?r=Surat to Sikar')}>
                Surat to Sikar Cab
              </h1> 
            </div>
          </div>
        </li>
      </ul>

       {/* ahemdabad  */}
       <ul className=''>
        <li className='cursor-pointer bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300'>
          <div>
            <h1 className='text-xl font-semibold mb-4 text-black'>
              One Way Taxi From Ahemdabad
            </h1>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2'>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-jodhpur?r=Ahmedabad To Jodhpur')}>
                Ahmedabad To Jodhpur Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-ankleshwar?r=Ahemdabad To Ankleshwar ')}>
                Ahmedabad To Ankleshwar  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-bharuch?r=Ahmedabad To Bharuch ')}>
                Ahmedabad To Bharuch  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-dhrangadhra?r=Ahmedabad To Dhrangadhra  ')}>
                Ahmedabad To Dhrangadhra Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-dahej?r=Ahmedabad To Dahej  ')}>
                Ahmedabad To Dahej  Cab
              </h1>

              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-dhari?r=Ahmedabad To Dhari  ')}>
                Ahmedabad To Dhari  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-gandhidham?r=Ahmedabad To Gandhidham  ')}>
                Ahmedabad To Gandhidham  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-himmatnagar?r=Ahmedabad To Himatnagar  ')}>
                Ahmedabad To Himatnagar  Cab
              </h1>

              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-jamnagar?r=Ahmedabad To Jamnagar  ')}>
                Ahmedabad To Jamnagar  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-bikaner?r=Ahmedabad To Bikaner  ')}>
                Ahmedabad To Bikaner  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-mumbai?r=Ahmedabad To Mumbai  ')}>
                Ahmedabad To Mumbai  Cab
              </h1>

              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-mountabu?r=Ahemdabad To MountAbu  ')}>
                Ahemdabad To Mount Abu Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-rajkot?r=Ahemdabad To Rajkot  ')}>
                Ahemdabad To Rajkot Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-surat?r=Ahemdabad To Surat  ')}>
                Ahemdabad To Surat Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-mumbai-to-innova?r=Ahmedabad To Mumbai Innova Cab  ')}>
                Ahmedabad To Mumbai Innova Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-udaipur?r=Ahmedabad To Udaipur   ')}>
                Ahmedabad To Udaipur Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-vadodara?r=Ahmedabad To Vadodara   ')}>
                Ahmedabad To Vadodara Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-bhuj?r=Ahmedabad To Bhuj    ')}>
                Ahmedabad To Bhuj  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-navsari?r=Ahmedabad To Navsari    ')}>
                Ahmedabad To Navsari  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-palitana?r=Ahmedabad To Palitana    ')}>
                Ahmedabad To Palitana  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-dakor?r=Ahmedabad To Dakor    ')}>
                Ahmedabad To Dakor  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-morbi?r=Ahmedabad To Morbi    ')}>
                Ahmedabad To Morbi  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-godhara?r=Ahmedabad To Godhara    ')}>
                Ahmedabad To Godhara  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-ujjain?r=Ahmedabad To Ujjain    ')}>
                Ahmedabad To Ujjain  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-indore?r=Ahmedabad To Indore    ')}>
                Ahmedabad To Indore  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-amreli?r=Ahmedabad To Amreli    ')}>
                Ahmedabad To Amreli  Cab
              </h1>

              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-veraval?r=Ahmedabad To Veraval    ')}>
                Ahmedabad To Veraval  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-aburoad?r=Ahmedabad To Abu Road    ')}>
                Ahmedabad To Abu Road  Cab
              </h1>

              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-delhi?r=Ahmedabad To Delhi')}>
                Ahmedabad To Delhi  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-botad?r=Ahmedabad To Botad')}>
                Ahmedabad To Botad  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-surendranagar?r=Ahmedabad To Surendranagar')}>
                Ahmedabad To Surendranagar  Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-bhavnagar?r=Ahmedabad To Bhavnagar')}>
                Ahmedabad To Bhavnagar Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-chittorgarh?r=Ahmedabad To Chittorgarh    ')}>
                Ahmedabad To Chittorgarh  Cab
              </h1>

              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-rajula?r=Ahmedabad To Rajula    ')}>
                Ahmedabad To Rajula  Cab
              </h1>

              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-dungarpur?r=Ahmedabad To Dungarpur     ')}>
                Ahmedabad To Dungarpur   Cab
              </h1>

              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-gondal?r=Ahmedabad To Gondal     ')}>
                Ahmedabad To Gondal   Cab
              </h1>

              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-jaipur?r=Ahmedabad To Jaipur     ')}>
                Ahmedabad To Jaipur Cab
              </h1>

              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-junagadh?r=Ahmedabad To Junagadh     ')}>
                Ahmedabad To Junagadh Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-palanpur?r=Ahmedabad To Palanpur     ')}>
                Ahmedabad To Palanpur Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-modasa?r=Ahmedabad To Modasa     ')}>
                Ahmedabad To Modasa Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                // onClick={() => navigate('/ahemdabad-to-goa?r=Ahmedabad To Goa     ')}
                onClick={() => {
                  navigate('/ahemdabad-to-goa?r=Ahmedabad To Goa');
                  window.scrollTo(0, 0); // Scrolls to the top on click
                }}
              >
                Ahmedabad To Goa Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-aburoad?r=Ahmedabad To Abu Road')}>
                Ahmedabad To Abu Road Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-nathdwara?r=Ahmedabad To Nathdwara')}>
                Ahmedabad To Nathdwara Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-patan?r=Ahmedabad To Patan')}>
                Ahmedabad To Patan Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-pune?r=Ahmedabad To Pune')}>
                Ahmedabad To Pune Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-rajpipla?r=Ahmedabad To Rajpipla')}>
                Ahmedabad To Rajpipla Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-somnath?r=Ahmedabad To Somnath')}>
                Ahmedabad To Somnath Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/ahemdabad-to-somnath?r=Ahmedabad To Somnath')}>
                Ahmedabad To Somnath Cab
              </h1>
            </div>
          </div>
        </li>
      </ul>


      {/* mumbai */}

      <ul className=''>
        <li className='cursor-pointer bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300'>
          <div>
            <h1 className='text-xl font-semibold mb-4 text-black'>
              One Way Taxi From Mumbai
            </h1>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2'>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => {
                  navigate('/mumbai-to-ahemdabad?r=Mumbai To Ahemdabad');
                  window.scrollTo(0, 0); // Scrolls to the top on click
                }}>
                Mumbai To Ahemdabad Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => {
                  navigate('/mumbai-to-ahmednagar?r=Mumbai To Ahmednagar');
                  window.scrollTo(0, 0); // Scrolls to the top on click
                }}>
                Mumbai To Ahmednagar Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => {
                  navigate('/mumbai-to-aurangabad?r=Mumbai To Aurangabad');
                  window.scrollTo(0, 0); // Scrolls to the top on click
                }}>
                Mumbai To Aurangabad Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => {
                  navigate('/mumbai-to-rajkot?r=Mumbai To Rajkot');
                  window.scrollTo(0, 0); // Scrolls to the top on click
                }}>
                Mumbai To Rajkot Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => {
                  navigate('/mumbai-to-bharuch?r=Mumbai To Bharuch');
                  window.scrollTo(0, 0); // Scrolls to the top on click
                }}>
                Mumbai To Bharuch Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => {
                  navigate('/mumbai-to-bikaner?r=Mumbai To Bikaner');
                  window.scrollTo(0, 0); // Scrolls to the top on click
                }}>
                Mumbai To Bikaner Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => {
                  navigate('/mumbai-to-dahej?r=Mumbai To Dahej');
                  window.scrollTo(0, 0); // Scrolls to the top on click
                }}>
                Mumbai To Dahej Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => {
                  navigate('/mumbai-to-deolali?r=Mumbai To Deolali');
                  window.scrollTo(0, 0); // Scrolls to the top on click
                }}>
                Mumbai To Deolali Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => {
                  navigate('/mumbai-to-goa?r=Mumbai To Goa');
                  window.scrollTo(0, 0); // Scrolls to the top on click
                }}>
                Mumbai To Goa Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-pune?r=Mumbai To Pune')
                }>
                Mumbai To Pune Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-satara?r=Mumbai To Satara')
                }>
                Mumbai To Satara  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-nashik?r=Mumbai To Nashik')
                }>
                Mumbai To Nashik  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-shirdi?r=Mumbai To Shirdi')
                }>
                Mumbai To Shirdi  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-surat?r=Mumbai To Surat')
                }>
                Mumbai To Surat  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-trimbakeshwar?r=Mumbai To Trimbakeshwar')
                }>
                Mumbai To Trimbakeshwar  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-vadodara?r=Mumbai To Vadodara')
                }>
                Mumbai To Vadodara  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-vapi?r=Mumbai To Vapi')
                }>
                Mumbai To Vapi  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-navsari?r=Mumbai To Navsari')
                }>
                Mumbai To Navsari  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-lonavala?r=Mumbai To Lonavala')
                }>
                Mumbai To Lonavala  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-khandala?r=Mumbai To Khandala')
                }>
                Mumbai To Khandala  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-bardoli?r=Mumbai To Bardoli')
                }>
                Mumbai To Bardoli  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-valsad?r=Mumbai To Valsad')
                }>
                Mumbai To Valsad  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-kolahpur?r=Mumbai To Kolahpur')
                }>
                Mumbai To Kolahpur  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-palghar?r=Mumbai To Palghar')
                }>
                Mumbai To Palghar  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-palanpur?r=Mumbai To Palanpur')
                }>
                Mumbai To Palanpur  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-malegaon?r=Mumbai To Malegaon')
                }>
                Mumbai To Malegaon  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-jalgaon?r=Mumbai To Jalgaon')
                }>
                Mumbai To Jalgaon  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-baramati?r=Mumbai To Baramati')
                }>
                Mumbai To Baramati  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-akola?r=Mumbai To Akola')
                }>
                Mumbai To Akola  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-latur?r=Mumbai To Latur')
                }>
                Mumbai To Latur  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-daman?r=Mumbai To Daman')
                }>
                Mumbai To Daman  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-osmanabad?r=Mumbai To Osmanabad')
                }>
                Mumbai To Osmanabad  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-jalna?r=Mumbai To Jalna')
                }>
                Mumbai To Jalna  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-nandurbar?r=Mumbai To Nandurbar')
                }>
                Mumbai To Nandurbar  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-dhule?r=Mumbai To Dhule')
                }>
                Mumbai To Dhule  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-khopoli?r=Mumbai To Khopoli')
                }>
                Mumbai To Khopoli  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-shirpur?r=Mumbai To Shirpur')
                }>
                Mumbai To Shirpur  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-alibag?r=Mumbai To Alibag')
                }>
                Mumbai To Alibag  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-ankleshwar?r=Mumbai To Ankleshwar')
                }>
                Mumbai To Ankleshwar  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-veraval?r=Mumbai To Veraval')
                }>
                Mumbai To Veraval  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-virar?r=Mumbai To VirarMaharashtar')
                }>
                Mumbai To Virar  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-matheran?r=Mumbai To Matheran')
                }>
                Mumbai To Matheran  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-indore?r=Mumbai To Indore')
                }>
                Mumbai To Indore  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-nagpur?r=Mumbai To Nagpur')
                }>
                Mumbai To Nagpur  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-solapur?r=Mumbai To Solapur')
                }>
                Mumbai To Solapur  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-silvassa?r=Mumbai To Silvassa')
                }>
                Mumbai To Silvassa  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-bhuj?r=Mumbai To Bhuj')
                }>
                Mumbai To Bhuj  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-udaipur?r=Mumbai To Udaipur')
                }>
                Mumbai To Udaipur  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-bhavnagar?r=Mumbai To Bhavnagar')
                }>
                Mumbai To Bhavnagar  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbaicentral-to-surat?r=MumbaiCentral To Surat')
                }>
                Mumbai Central To Surat  Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-surat-sedan?r=Mumbai To Surat')
                }>
                Mumbai To Surat Dzire  Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-surat-innovacrysta?r=Mumbai To Surat')
                }>
                Mumbai To Surat Innova Crysta  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-surat-tempo?r=Mumbai To Surat')
                }>
                Mumbai To Surat Tempo Traveller  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-surat-innova?r=Mumbai To Surat')
                }>
                Mumbai To Surat Innova Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-satara?r=Mumbai To Satara')
                }>
                Mumbai To Satara Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-badalpur?r=Mumbai To Badlapur')
                }>
                Mumbai To Badlapur Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-bhimashankar?r=Mumbai To Bhimashankar')
                }>
                Mumbai To Bhimashankar Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-umargam?r=Mumbai To Umargam')
                }>
                Mumbai To Umargam Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-parbhani?r=Mumbai To Parbhani')
                }>
                Mumbai To Parbhani Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-kopargaon?r=Mumbai To Kopargaon')
                }>
                Mumbai To Kopargaon Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-mahad?r=Mumbai To Mahad')
                }>
                Mumbai To Mahad Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-jabalpur?r=Mumbai To Jabalpur')
                }>
                Mumbai To Jabalpur Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-ambernath?r=Mumbai To Ambernath')
                }>
                Mumbai To Ambernath Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-padgha?r=Mumbai To Padgha')
                }>
                Mumbai To Padgha Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-bhilad?r=Mumbai To Bhilad')
                }>
                Mumbai To Bhilad Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-vikramgad?r=Mumbai To Vikramgad')
                }>
                Mumbai To Vikramgad Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-vajreshwari?r=Mumbai To Vajreshwari')
                }>
                Mumbai To Vajreshwari Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-chandwad?r=Mumbai To Chandwad')
                }>
                Mumbai To Chandwad Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-gandhinagar?r=Mumbai To Gandhinagar')
                }>
                Mumbai To Gandhinagar Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-hazira?r=Mumbai To Hazira')
                }>
                Mumbai To Hazira Cab
              </p>
              
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-nadiad?r=Mumbai To Nadiad')
                }>
                Mumbai To Nadiad Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-Dharampur?r=Mumbai To Dharampur')
                }>
                Mumbai To Dharampur Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-karjat?r=Mumbai To Karjat')
                }>
                Mumbai To Karjat Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-boisar?r=Mumbai To Boisar')
                }>
                Mumbai To Boisar Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-kheda?r=Mumbai To Kheda')
                }>
                Mumbai To Kheda Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-saputara?r=Mumbai To Saputara')
                }>
                Mumbai To Saputara Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-gandevi?r=Mumbai To Gandevi')
                }>
                Mumbai To Gandevi Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-bhusawal?r=Mumbai To Bhusawal')
                }>
                Mumbai To Bhusawal Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-tarapur?r=Mumbai To Tarapur')
                }>
                Mumbai To Tarapur Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-murbad?r=Mumbai To Murbad')
                }>
                Mumbai To Murbad Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-anjangaon?r=Mumbai To Anjangaon')
                }>
                Mumbai To Anjangaon Cab
              </p> 

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-jalna?r=Mumbai To Jalna')
                }>
                Mumbai To Jalna Cab
              </p> 
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-vyara?r=Mumbai To Vyara')
                }>
                Mumbai To Vyara Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-sangamner?r=Mumbai To Sangamner')
                }>
                Mumbai To Sangamner Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-ratnagiri?r=Mumbai To Ratnagiri')
                }>
                Mumbai To Ratnagiri Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-ratnagiri?r=Mumbai To Ratnagiri')
                }>
                Mumbai To Ratnagiri Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-sangali?r=Mumbai To Sangli')
                }>
                Mumbai To Sangli Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-hyderabad?r=Mumbai To Hyderabad')
                }>
                Mumbai To Hyderabad Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-pardi?r=Mumbai To Pardi')
                }>
                Mumbai To Pardi Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-chakan?r=Mumbai To Chakan')
                }>
                Mumbai To Chakan Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-rasayani?r=Mumbai To Rasayani')
                }>
                Mumbai To Rasayani Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-wada?r=Mumbai To Wada')
                }>
                Mumbai To Wada Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-kasara?r=Mumbai To Kasara')
                }>
                Mumbai To Kasara Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-shahapur?r=Mumbai To Shahapur')
                }>
                Mumbai To Shahapur Cab
              </p> 

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-nagpur?r=Mumbai To Nagpur')
                }>
                Mumbai To Nagpur Cab
              </p> 

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-nandurbar?r=Mumbai To Nandurbar')
                }>
                Mumbai To Nandurbar Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-nagothane?r=Mumbai To Nagothane')
                }>
                Mumbai To Nagothane Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-sinnar?r=Mumbai To Sinnar')
                }>
                Mumbai To Sinnar Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-beed?r=Mumbai To Beed')
                }>
                Mumbai To Beed Cab
              </p>

              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-igatpuri?r=Mumbai To Igatpuri')
                }>
                Mumbai To Igatpuri Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-bhopal?r=Mumbai To Bhopal')
                }>
                Mumbai To Bhopal Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-dahanu?r=Mumbai To Dahanu')
                }>
                Mumbai To Dahanu Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-bilimora?r=Mumbai To Bilimora')
                }>
                Mumbai To Bilimora Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-bengaluru?r=Mumbai To Bengaluru')
                }>
                Mumbai To Bengaluru Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-chikhali?r=Mumbai To Chikhali')
                }>
                Mumbai To Chikhali Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-manor?r=Mumbai To Manor')
                }>
                Mumbai To Manor Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-lasalgaon?r=Mumbai To Lasalgaon')
                }>
                Mumbai To Lasalgaon Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-sakri?r=Mumbai To Sakri')
                }>
                Mumbai To Sakri Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() =>
                  navigate('/mumbai-to-udvada?r=Mumbai To Udvada')
                }>
                Mumbai To Udvada Cab
              </p>
            </div>
          </div>
        </li>
      </ul>


    {/* Daman */}
        <ul className=''>
        <li className='cursor-pointer bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300'>
          <div> 
            <h1 className='text-xl font-semibold mb-4 text-black'>
              One Way Taxi From Daman
            </h1>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2'>
             
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => {
                  navigate('/daman-to-mumbai?r=Daman to Mumbai');}}>
                Daman to Mumbai Cab
              </h1>
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-mehsana?r=Daman to Mehsana')}>
                Daman to Mehsana  Cab
              </h1> 
              <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-surat?r=Daman to Surat')}>
                Daman to Surat  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-ankleshwar?r=Daman to Ankleshwar')}>
                Daman to Ankleshwar  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-goa?r=Daman to Goa')}>
                Daman to Goa  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-surat?r=Daman to Surat')}>
                Daman to Surat  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-bardoli?r=Daman to Bardoli')}>
                Daman to Bardoli  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-bharuch?r=Daman to Bharuch')}>
                Daman to Bharuch  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-kalyan?r=Daman to Kalyan')}>
                Daman to Kalyan  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-lonavala?r=Daman to Lonavala')}>
                Daman to Lonavala  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-nashik?r=Daman to Nashik')}>
                Daman to Nashik  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-navsari?r=Daman to Navsari')}>
                Daman to Navsari  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-pune?r=Daman to Pune')}>
                Daman to Pune  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-rajkot?r=Daman to Rajkot')}>
                Daman to Rajkot  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-surat?r=Daman to Surat')}>
                Daman to Surat  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-shirdi?r=Daman to Shirdi')}>
                Daman to Shirdi  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-surat?r=Daman to Surat')}>
                Daman to Surat  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-MumbaiAirport?r=Daman to Mumbai Airport')}>
                Daman to Mumbai Airport  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-hazira?r=Daman to Hazira')}>
                Daman to Hazira  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-dahej?r=Daman to Dahej')}>
                Daman to Dahej  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-vasai-virar?r=Daman to Vasai Virar')}>
                Daman to Vasai Virar  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-bhiwandi?r=Daman to Bhiwandi')}>
                Daman to Bhiwandi  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-badlapur?r=Daman to Badlapur')}>
                Daman to Badlapur  Cab
              </h1> <h1 className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                 onClick={() => navigate('/daman-to-Mumbai?r=Daman to Mumbai')}>
                Daman to Navi Mumbai  Cab
              </h1> 
            </div>
          </div>
        </li>
      </ul>


{/* vapi */}

      <ul className=''>
        <li className='cursor-pointer bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300'>
          <div>
            <h1 className='text-xl font-semibold mb-4 text-black'>
              One Way Taxi From Vapi
            </h1>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2'>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-mumbai?r=Vapi To Mumbai')}>
               Vapi To Mumbai Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-mumbaiairport?r=Vapi To Mumbai Airport')}>
               Vapi To Mumbai Airport Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-navimumbai?r=Vapi To Navi Mumbai')}>
               Vapi To Navi Mumbai Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-daman?r=Vapi To Daman')}>
               Vapi To Daman Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-ahmedabad?r=Vapi To Ahmedabad')}>
               Vapi To Ahmedabad Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-nagpur?r=Vapi To Nagpur')}>
               Vapi To Nagpur Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-solapur?r=Vapi To Solapur')}>
               Vapi To Solapur Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-surat?r=Vapi To Surat')}>
               Vapi To Surat Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-thane?r=Vapi To Thane')}>
               Vapi To Thane Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-nashik?r=Vapi To Nashik')}>
               Vapi To Nashik Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-pune?r=Vapi To Pune')}>
               Vapi To Pune Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-shirdi?r=Vapi To Shirdi')}>
               Vapi To Shirdi Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-bhiwandi?r=Vapi To Bhiwandi')}>
               Vapi To Bhiwandi Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-badlapur?r=Vapi To Badlapur')}>
               Vapi To Badlapur Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-dadar?r=Vapi To Dadar')}>
               Vapi To Dadar Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-panvel?r=Vapi To Panvel')}>
               Vapi To Panvel Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-mumbaicentral?r=Vapi To Mumbai Central')}>
               Vapi To Mumbai Central Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-rajkot?r=Vapi To Rajkot')}>
               Vapi To Rajkot Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-bhuj?r=Vapi To Bhuj')}>
               Vapi To Bhuj Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-jamnagar?r=Vapi To Jamnagar')}>
               Vapi To Jamnagar Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-udaipur?r=Vapi To Udaipur')}>
               Vapi To Udaipur Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-indore?r=Vapi To Indore')}>
               Vapi To Indore Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-goa?r=Vapi To Goa')}>
               Vapi To Goa Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-lonavala?r=Vapi To Lonavala')}>
               Vapi To Lonavala Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-anand?r=Vapi To Anand')}>
               Vapi To Anand Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-bharuch?r=Vapi To Bharuch')}>
               Vapi To Bharuch Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/vapi-to-vadodara?r=Vapi To Vadodara')}>
               Vapi To Vadodara Cab
              </p>
              
              
              
            </div>
          </div>
        </li>
      </ul>




{/* valsad */}
      <ul className=''>
        <li className='cursor-pointer bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300'>
          <div>
            <h1 className='text-xl font-semibold mb-4 text-black'>
              One Way Taxi From Valsad
            </h1>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2'>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-ahemdabd?r=Valsad To Ahemdabad')}>
                Valsad To Ahemdabad Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-bhopal?r=Valsad To Bhopal')}>
                Valsad To Bhopal Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-daman?r=Valsad To Daman')}>
                Valsad To Daman Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-goa?r=Valsad To Goa')}>
                Valsad To Goa Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-Hazira?r=Valsad To Hazira')}>
                Valsad To Hazira Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-indore?r=Valsad To Indore')}>
                Valsad To Indore Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-lonavala?r=Valsad To Lonavala')}>
                Valsad To Lonavala Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-mumbai?r=Valsad To Mumbai')}>
                Valsad To Mumbai Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-nashik?r=Valsad To Nashik')}>
                Valsad To Nashik Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-navsari?r=Valsad To Navsari')}>
                Valsad To Navsari Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-pune?r=Valsad To Pune')}>
                Valsad To Pune Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-shirdi?r=Valsad To Shirdi')}>
                Valsad To Shirdi Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-silvassa?r=Valsad To Silvassa')}>
                Valsad To Silvassa Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-surat?r=Valsad To Surat')}>
                Valsad To Surat Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-ujjain?r=Valsad To Ujjain')}>
                Valsad To Ujjain Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-vadodara?r=Valsad To Vadodara')}>
                Valsad To Vadodara Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/valsad-to-vapi?r=Valsad To Vapi')}>
                Valsad To Vapi Cab
              </p>
            </div>
          </div>
        </li>
      </ul>

      {/* amreli */}
      <ul className=''>
        <li className='cursor-pointer bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300'>
          <div>
            <h1 className='text-xl font-semibold mb-4 text-black'>
              One Way Taxi From Amreli
            </h1>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2'>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-surat?r=Amreli To Surat')}>
                Amreli To Surat Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-babra?r=Amreli To Babra')}>
                Amreli To Babra Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-rajula?r=Amreli To Rajula')}>
                Amreli To Rajula Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-rajkot?r=Amreli To Rajkot')}>
                Amreli To Rajkot Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-pune?r=Amreli To Pune')}>
                Amreli To Pune Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-ahmedabadairport?r=Amreli To Ahmedabad Airport')}>
                Amreli To Ahmedabad Airport Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-dhari?r=Amreli To Dhari')}>
                Amreli To Dhari Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-jamnagar?r=Amreli To Jamnagar')}>
                Amreli To Jamnagar Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-vadodara?r=Amreli To Vadodara')}>
                Amreli To Vadodara Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-navsari?r=Amreli To Navsari')}>
                Amreli To Navsari Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-diu?r=Amreli To Diu')}>
                Amreli To Diu Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-chotila?r=Amreli To Chotila')}>
                Amreli To Chotila Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-dwarka?r=Amreli To Dwarka')}>
                Amreli To Dwarka Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-goa?r=Amreli To Goa')}>
                Amreli To Goa Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-ramdevra?r=Amreli To Ramdevra')}>
                Amreli To Ramdevra Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-veraval?r=Amreli To Veraval')}>
                Amreli To Veraval Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-junagadh?r=Amreli To Junagadh')}>
                Amreli To Junagadh Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-ahemdabad?r=Amreli To Ahemdabad')}>
                Amreli To Ahemdabad Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-rajkotairport?r=Amreli To Rajkot Airport')}>
                Amreli To Rajkot Airport Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-bhavnagar?r=Amreli To Bhavnagar')}>
                Amreli To Bhavnagar Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-palitana?r=Amreli To Palitana')}>
                Amreli To Palitana Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-savarkundla?r=Amreli To Savarkundla')}>
                Amreli To Savarkundla Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-mumbai?r=Amreli To Mumbai')}>
                Amreli To Mumbai Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-ambardisafaripark?r=Amreli To Ambardi Safari Park')}>
                Amreli To Ambardi Safari Park Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-girnationalpark?r=Amreli To Gir National Park')}>
                Amreli To Gir National Park Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/amreli-to-sasangir?r=Amreli To Sasan Gir')}>
                Amreli To Sasan Gir Cab
              </p>
            </div>
          </div>
        </li>
      </ul>

     {/* Silvassa */}
     <ul className=''>
        <li className='cursor-pointer bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300'>
          <div>
            <h1 className='text-xl font-semibold mb-4 text-black'>
              One Way Taxi From Silvassa
            </h1>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2'>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/silvassa-to-mumbai?r=Silvassa To Mumbai')}>
                Silvassa to Mumbai Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/silvassa-to-goa?r=Silvassa To Goa')}>
                Silvassa to Goa Cab
              </p> 
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/silvassa-to-ahmedabad?r=Silvassa To Ahmedabad')}>
                Silvassa to Ahmedabad Cab
              </p> 
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/silvassa-to-lonavala?r=Silvassa To Lonavala')}>
                Silvassa to Lonavala Cab
              </p> 
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/silvassa-to-mumbai?r=Silvassa To Mumbai')}>
                Silvassa to  Mumbai Airport Cab
              </p> 
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/silvassa-to-mumbai?r=Silvassa To Mumbai')}>
                Silvassa to Navi Mumbai Cab
              </p> 
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/silvassa-to-nashik?r=Silvassa To Nashik')}>
                Silvassa to Nashik Cab
              </p> 
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/silvassa-to-pune?r=Silvassa To Pune')}>
                Silvassa to Pune Cab
              </p> 
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/silvassa-to-kalyan?r=Silvassa To Kalyan')}>
                Silvassa to Kalyan Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/silvassa-to-thane?r=Silvassa To Thane')}>
                Silvassa to Thane Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/silvassa-to-rajkot?r=Silvassa To Rajkot')}>
                Silvassa to Rajkot Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/silvassa-to-udaipur?r=Silvassa To Udaipur')}>
                Silvassa to Udaipur Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/silvassa-to-boisar?r=Silvassa To Boisar')}>
                Silvassa to Boisar Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/silvassa-to-VasaiVirar?r=Silvassa To VasaiVirar')}>
                Silvassa to Vasai Virar Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/silvassa-to-surat?r=Silvassa To Surat')}>
                Silvassa to Surat Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/silvassa-to-vadodara?r=Silvassa To Vadodara')}>
                Silvassa to Vadodara Cab
              </p>
                           
            </div>
          </div>
        </li>
      </ul>


      {/* bardoli */}

      <ul className=''>
        <li className='cursor-pointer bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300'>
          <div>
            <h1 className='text-xl font-semibold mb-4 text-black'>
              One Way Taxi From Bardoli
            </h1>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2'>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-mumbai?r=Bardoli To Mumbai')}>
                Bardoli To Mumbai Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-kalyan?r=Bardoli To Kalyan')}>
                Bardoli To Kalyan Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-thane?r=Bardoli To Thane')}>
                Bardoli To Thane Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-bhiwandi?r=Bardoli To Bhiwandi')}>
                Bardoli To Bhiwandi Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-badlapur?r=Bardoli To Badlapur')}>
                Bardoli To Badlapur Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-dadar?r=Bardoli To Dadar')}>
                Bardoli To Dadar Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-panvel?r=Bardoli To Panvel')}>
                Bardoli To Panvel Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-mumbaicentral?r=Bardoli To Mumbai Central')}>
                Bardoli To Mumbai Central Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-vasaivirar?r=Bardoli To Vasai Virar')}>
                Bardoli To Vasai Virar Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-mumbaiairport?r=Bardoli To Mumbai Airport')}>
                Bardoli To Mumbai Airport Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-navimumbai?r=Bardoli To Navi Mumbai')}>
                Bardoli To Navi Mumbai  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-pune?r=Bardoli To Pune')}>
                Bardoli To Pune  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-ahmedabadairport?r=Bardoli To Ahmedabad Airport')}>
                Bardoli To Ahmedabad Airport  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-suratairport?r=Bardoli To Surat Airport')}>
                Bardoli To Surat Airport  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-nashik?r=Bardoli To Nashik')}>
                Bardoli To Nashik  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-shirdi?r=Bardoli To Shirdi')}>
                Bardoli To Shirdi  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-rajkot?r=Bardoli To Rajkot')}>
                Bardoli To Rajkot  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-indore?r=Bardoli To Indore')}>
                Bardoli To Indore  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-udaipur?r=Bardoli To Udaipur')}>
                Bardoli To Udaipur  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-daman?r=Bardoli To Daman')}>
                Bardoli To Daman  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-anand?r=Bardoli To Anand')}>
                Bardoli To Anand  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-bharuch?r=Bardoli To Bharuch')}>
                Bardoli To Bharuch  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-bhuj?r=Bardoli To Bhuj')}>
                Bardoli To Bhuj  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-goa?r=Bardoli To Goa')}>
                Bardoli To Goa  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-jamnagar?r=Bardoli To Jamnagar')}>
                Bardoli To Jamnagar  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-navsari?r=Bardoli To Navsari')}>
                Bardoli To Navsari  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-silvassa?r=Bardoli To Silvassa')}>
                Bardoli To Silvassa  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-vadodara?r=Bardoli To Vadodara')}>
                Bardoli To Vadodara  Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bardoli-to-vapi?r=Bardoli To Vapi')}>
                Bardoli To Vapi  Cab
              </p>
            </div>
          </div>
        </li>
      </ul>




{/* bharuch */}
      <ul className=''>
        <li className='cursor-pointer bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300'>
          <div>
            <h1 className='text-xl font-semibold mb-4 text-black'>
              One Way Taxi From Bharuch
            </h1>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2'>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-surat?r=Bharuch to Surat')}>
                Bharuch to Surat Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-ahmairport?r=Bharuch to Ahmedabad Airport')}>
                Bharuch to Ahmedabad Airport Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-mumbaiairport?r=Bharuch to Mumbai Airport')}>
                Bharuch to Mumbai Airport Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-pune?r=Bharuch to Pune')}>
                Bharuch to Pune Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-daman?r=Bharuch to Daman')}>
                Bharuch to Daman Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-vadodara?r=Bharuch to Vadodara')}>
                Bharuch to Vadodara Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-udaipur?r=Bharuch to Udaipur')}>
                Bharuch to Udaipur Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-vapi?r=Bharuch to Vapi')}>
                Bharuch to Vapi Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-silvassa?r=Bharuch to Silvassa')}>
                Bharuch to Silvassa Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-kalyan?r=Bharuch to Kalyan')}>
                Bharuch to Kalyan Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-bhiwandi?r=Bharuch to Bhiwandi')}>
                Bharuch to Bhiwandi Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-navimumbai?r=Bharuch to Navi Mumbai')}>
                Bharuch to Navi Mumbai Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-badlapur?r=Bharuch to Badlapur')}>
                Bharuch to Badlapur Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-ajmer?r=Bharuch to Ajmer')}>
                Bharuch to Ajmer Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-pune?r=Bharuch to Pune')}>
                Bharuch to Pune Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-goa?r=Bharuch to Goa')}>
                Bharuch to Goa Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-rajkot?r=Bharuch to Rajkot')}>
                Bharuch to Rajkot Cab
              </p>
              <p className='text-sm font-[500] bg-gray-100 rounded-md p-2 cursor-pointer'
                onClick={() => navigate('/bharuch-to-indore?r=Bharuch to Indore')}>
                Bharuch to Indore Cab
              </p>
            </div>
          </div>
        </li>
      </ul>


    </div>
  );
};

export default RouteList;
