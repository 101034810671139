import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'; // For page refresh animations
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import OneWayPage from './OneWayPage';
import RoundTripPage from './RoundTripPage';
import HourlyRent from './HourlyRent';
import AirportPage from './form_to_AirportPage'; 

const BookingForm = () => {
  const [tripType, setTripType] = useState('ONE WAY');

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS for scroll animations
  }, []);

  const handleTripTypeChange = (type) => {
    setTripType(type);
  };

  return (
    
    <>
      <motion.div 
        className="bg-cover bg-center bg-dunes h-[250px]" 
        initial={{ opacity: 0, y: -50 }} 
        animate={{ opacity: 1, y: 0 }} 
        transition={{ duration: 0.7 }} // Animation on load
      >
        <div className="flex flex-col items-center justify-center p-4">
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-[41px] font-bold text-white text-center mt-8 font-headingFont"
            data-aos="fade-down" // AOS for scroll effect
          >
            ENJOY EASY TRAVEL WITH CLASSIC HINDUSTAN TAXIS.
          </h1>

          <motion.div 
            className="bg-white max-w-[1200px] w-full shadow-lg p-4 mt-16 rounded-[16px] relative z-10"
            initial={{ scale: 0.8, opacity: 0 }} 
            animate={{ scale: 1, opacity: 1 }} 
            transition={{ duration: 0.8 }} // Animation on load
          >
            <div className="flex justify-center mb-4">
              <div className="grid grid-cols-2 gap-3 sm:grid-cols-4">
                {['ONE WAY', 'ROUND TRIP', 'LOCAL', 'AIRPORT'].map((type) => (
                  <motion.button
                    key={type}
                    className={`py-3 px-4 rounded-lg font-semibold text-[13px] transition-all duration-300 ease-in-out transform ${
                      tripType === type
                        ? 'bg-customBlue text-white shadow-lg'
                        : 'bg-white text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-black hover:shadow-md'
                    }`}
                    onClick={() => handleTripTypeChange(type)}
                   // Hover animation
                    whileTap={{ scale: 0.95 }}  // Tap animation
                  >
                    {type}
                  </motion.button>
                ))}
              </div>
            </div>
            <div className="w-full mt-4" data-aos="fade-up"> {/* AOS animation on scroll */}
              {tripType === 'ONE WAY' && <OneWayPage />}
              {tripType === 'ROUND TRIP' && <RoundTripPage />}
              {tripType === 'LOCAL' && <HourlyRent />}
              {tripType === 'AIRPORT' && <AirportPage />}
            </div>
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};

export default BookingForm;
