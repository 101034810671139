import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function VapiToVapi() {
    const location = useLocation();
    const [place1, setPlace1] = useState('');
    const [place2, setPlace2] = useState('');
    const [selectCarDetails, setSelectCarDetails] = useState([]);
    const [distance, setDistance] = useState([]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const titleFromQuery = queryParams.get('r');
        console.log('titleFromQuery =>', titleFromQuery);

        if (titleFromQuery) {
            const decodedTitle = decodeURIComponent(titleFromQuery);
            const places = decodedTitle.split(' ');

            if (places.length >= 2) {
                setPlace1(places[0]);
                setPlace2(places[2]);
            } else {
                console.log('The title does not contain two parts');
            }

            console.log('Decoded Title =>', decodedTitle);
        }
    }, [location.search]);

    useEffect(() => {
        if (place1 && place2) {
            const apiUrl = 'https://bhadegadiapi.taxiwalataxi.in/api/v1/adddistance';
            const postData = {
                cityname1: place1,
                cityname2: place2
            };

            const fetchDistance = async () => {
                try {
                    const response = await fetch(apiUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            // Add any additional headers if required
                        },
                        body: JSON.stringify(postData),
                    });

                    // Check if the response is successful
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    // Parse the JSON response
                    const data = await response.json();
                    console.log('data9999 =>', data);
                   const roundedDistance = data?.result?.distance || 0;
                    const dist = Math.ceil(roundedDistance);



                    console.log('dist =>', dist);
                    // Calculate price based on distance
                    let calculatedDistance;
                    if (dist > 0 && dist < 50) {
                        calculatedDistance = 125;
                    } else if (dist >= 50 && dist < 100) {
                        calculatedDistance = dist * 2.5;
                    } else if (dist >= 100 && dist < 150) {
                        calculatedDistance = dist * 2;
                    } else if (dist >= 150 && dist <= 300) {
                        calculatedDistance = 300;
                    } else {
                        calculatedDistance = dist; // Default case
                    }

                    setDistance({
                        ...data,
                        result: {
                            ...data.result,
                            calculatedDistance
                        }
                    });
                } catch (error) {
                    console.error('Error:', error);
                }
            };
            fetchDistance();
        }
    }, [place1, place2]);

    useEffect(() => {
        const apiUrl = `https://bhadegadiapi.taxiwalataxi.in/api/v1/searchalldata?type=onewayTrip`;

        const fetchCarDetails = async () => {
            try {
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log('Car Details:', data.data);
                setSelectCarDetails(data?.data?.taxiDetails || []);
            } catch (error) {
                console.error('Error fetching car details:', error);
            }
        };

        fetchCarDetails();
    }, []);

    return (
        <div className="container p-6 text-justify">
            <h1 className='text-center text-lg sm:text-3xl py-5 capitalize text-gray-800 font-[600]'>

                Vapi To Surat Cab Service Starting From ₹2808 | Luxurious New Model Cabs & Experienced Drivers For Vapi To Surat Taxi Service.
            </h1>

            <h2 className='text-lg font-[600]  text-gray-800  '>
                    Vapi To Surat Taxi @ ₹2808/-  New Model Cab, Experienced Driver, Cheapest Rate. Call Now On +917600060604 For Instant Book And Get Discount.
                </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3 py-5">
                {selectCarDetails.length > 0 ? (
                    selectCarDetails.map((car, index) => {
                        const actualPrice = distance?.result?.calculatedDistance * car.onewayTrip.actualRatePerKM || 0;
                        console.log('actualPrice =>', distance);
                        const discountPrice = distance?.result?.calculatedDistance * car.onewayTrip.discountRatePerKM || 0;
                        return <>
                            <div
                                key={index}
                                className="border border-gray-300 p-4 rounded-lg shadow-md  flex flex-col items-start bg-black " >
                                <h3 className="text-[13px] py-1  w-full border bg-customBlue border-customBlue text-black font-[600] px-3 my-1 rounded">{car?.Name}</h3>

                                <p className=' w-full py-1   text-sm  flex justify-between border border-customBlue text-customBlue my-1 px-3 text-sm rounded'>
                                    <p>Type :- </p>
                                    <p > {car?.Type} </p>
                                </p>
                                <p className=' w-full py-1 flex   text-sm justify-between border border-customBlue text-customBlue my-1 px-3 rounded'>
                                    <p>Seats :-</p>
                                    <p> {car?.seats}</p>
                                </p>
                                <p className=' w-full py-1 flex  text-sm   justify-between border border-customBlue text-customBlue my-1 px-3 rounded'>
                                    <p>AC :-</p>
                                    <p> {car?.IsAcCar ? 'Yes' : 'No'}</p>
                                </p>
                                <p className=' w-full py-1 flex  text-sm  justify-between border border-customBlue text-customBlue my-1 px-3 rounded'>
                                    <p>Boot Space :-</p>
                                    <p> {car?.BootSpace} </p>
                                </p>
                                <p className=' w-full py-1 flex  text-sm   justify-between border border-customBlue text-customBlue my-1 px-3 rounded'>
                                    <p>Availability :-</p>
                                    <p> {car?.onewayTrip?.isAvailable ? 'Available' : 'Not Available'} </p>
                                </p>
                                <p className='w-full flex py-1 justify-between text-sm  border border-customBlue text-customBlue my-1 px-3 rounded'>
                                    <p>Price :-</p>
                                    <p className='bg-customBlue rounded font-[600] text-black px-2 mr-[-5px]'> ₹ {Math.floor(actualPrice)} </p>
                                </p>
                                <Link to='/' className='w-full'>
                                    <button className=' w-full text-black font-[600] py-1 text-center justify-between text-sm  bg-customBlue  my-1 px-3 rounded'> Book Now
                                    </button>
                                </Link>



                            </div></>
                    })
                ) : (
                    <p>No car details found.</p>
                )}
            </div>
            <div style={{ fontWeight: '100' }} className='text-gray-600'>
            

                <h1 className='pt-2 text-black font-bold mb-3 text-lg'>Hindustan Taxi Service: Your Reliable  Vapi To Surat Cab & Taxi Service</h1>

                <p className='py-2 text-black'>Are you looking for a reliable, affordable, and safe  Vapi To Surat cab service? <b>
                    Hindustan Taxi Service </b> offers the best transportation solutions for your travel needs, whether you are planning a one-way trip or a round-trip journey. Our extensive fleet, experienced drivers, and transparent pricing make us the go-to option for passengers traveling between  Vapi To Surat.</p>

                <h1 className='font-bold pt-5 text-black text-lg mb-2'> Vapi To Surat One-Way Taxi Service</h1>
                <p className='pb-5 text-black'>For those who need a  Vapi To Surat one-way taxi, Hindustan Taxi Service offers unbeatable comfort and convenience. Our one-way cab service is perfect for travelers who are headed to Surat for business, leisure, or to catch a flight. You can easily book a one-way cab from  Vapi To Surat online, ensuring a smooth and affordable journey.</p>


                <h1 className='pt-5 text-black font-bold mb-3 text-lg'>Key Features of Our One-Way Taxi Service: </h1>


                <p className='font-bold text-black'><b className=' text-black'> Affordable pricing: </b> We offer competitive fares with no hidden charges. </p>
                <p className='font-bold text-black'><b className=' text-black'>  Variety of vehicles:</b>  Choose from Sedans, SUVs, Innova, Ertiga, and more. </p>
                <p className='font-bold text-black'> <b className=' text-black'> Safe and professional drivers: </b> Experienced drivers ensure a stress-free journey. </p>
                <p className='pb-5 font-bold text-black'><b className=' text-black'>  24/7 service: </b> No matter what time you need to travel, we’ve got you covered.</p>




                <p className='font-bold text-black text-lg mb-1'> Vapi To Surat Round-Trip Cab Service </p>
                <p className='text-black'>If you're planning to return to Vapi, we offer  Vapi To Surat round-trip taxi services that are perfect for a day trip or weekend getaway. Whether you’re traveling for leisure or business, our round-trip cabs ensure that you enjoy a comfortable journey both ways. You can also customize your trip with stops at popular destinations along the way.</p>


                <p className='pt-5 text-black text-lg font-bold mb-1'> Benefits of Our Round-Trip Taxi Service:</p>

                <p className='text-black'> <b className='font-bold text-black'> Cost-effective packages: </b>  Save money with our round-trip taxi options. </p>

                <p className='text-black'> <b className='font-bold text-black'>Comfortable fleet: </b>  Choose from our range of vehicles, including Sedans, Innova Crysta, Ertiga, and Force Urbania. </p>
                <p className='text-black'> <b className='font-bold text-black'>Same driver for the entire journey:</b>  This adds convenience and safety to your trip. </p>
                <p className='text-black'> <b className='font-bold text-black'>Flexible booking: </b>  Easily book online or call us to schedule your trip. </p>

                <div className='pt-5 text-black'>
                    <p className=' text-black text-lg mb-1 font-bold'>Why Choose  Hindustan Taxi Service for Your  Vapi To Surat Journey ?</p>
                    <p> <b className='font-bold text-black'> Reliable Taxi Service - Hindustan Taxi Service:</b> We are known for providing one of the best  Vapi To Surat taxi services, with a reputation for punctuality, reliability, and affordability.</p>
                    <p> <b className='font-bold text-black'> Transparent Pricing: </b> Whether you’re booking a one-way cab or a round-trip taxi, our fares are clear with no hidden charges.</p>
                    <p> <b className='font-bold text-black'> Comfortable Fleet: </b> From SUVs to Sedans and Innova Crysta, we have a vehicle for every travel need.</p>
                    <p> <b className='font-bold text-black'> Experienced Drivers: </b> Our professional drivers ensure a safe and smooth ride for every passenger.</p>
                    <p> <b className='font-bold text-black'> Flexible Booking: </b> You can easily book your  Vapi To Surat cab online and choose your vehicle and travel time as per your convenience.</p>
                </div>


                <div className='pt-5 text-black'>
                    <p className='font-bold text-black text-lg mb-1'>Types of Vehicles We Offer</p>
                    <p>We offer a range of vehicles to suit your travel needs. Choose from:</p>
                    <p> <b className='font-bold text-black'> Sedans: </b> For a smooth and comfortable ride, perfect for solo travelers or couples.</p>
                    <p> <b className='font-bold text-black'> Innova Crysta: </b> Ideal for families or groups, with ample space for luggage.</p>
                    <p> <b className='font-bold text-black'> Ertiga: </b> A budget-friendly option for group travel. </p>
                    <p> <b className='font-bold text-black'> Force Urbania: </b> Perfect for large groups, offering plenty of space and comfort.</p>

                </div>

                <div className='pt-5 text-black'>
                    <p className='font-bold text-black text-lg mb-1'>Affordable Pricing for All Types of Trips</p>
                    <p>Our  Vapi To Surat taxi fare is one of the most competitive in the market. We offer a range of vehicles to fit your budget, whether you’re looking for an affordable one-way taxi or a luxury round-trip cab.</p>
                </div>
                <div className='pt-5 text-black'>
                    <h1 className='font-bold text-black text-lg mb-1'> Vapi To Surat Airport Taxi</h1>
                    <p>Need a ride to Vapi To Surat? We provide special  Vapi To Surat airport taxi services, ensuring that you reach your terminal on time without any hassle. Our drivers are familiar with the fastest routes and can accommodate your schedule, making sure you never miss a flight.</p>
                </div>
                <div className='pt-5 text-black'>
                <h1 className='font-bold text-black text-lg mb-1'>How to Book Your  Vapi To Surat Taxi Service</h1>
                    <p>Booking with <b className='font-bold text-black'> Hindustan Taxi Service </b> is simple:</p>
                    <p>  <b className='font-bold text-black'>  Visit our website </b> or <b> call us </b> to book your taxi.</p>
                    <p> <b className='font-bold text-black'> Select your vehicle </b> based on your needs choose from <b> Sedans, SUVs, Innova Crysta,</b> and more.</p>
                    <p>Get an instant quote and confirm your booking.</p>
                    <p> Enjoy your hassle-free journey from  Vapi To Surat. </p>
                </div>

                <div className='mt-5 text-black'>
                    <h1 className='font-bold text-black text-lg mb-1'>Why Our Customers Trust Us ?</h1>
                    <p> <b className='font-bold text-black' > Safe and Secure Travel: </b> All our vehicles are well-maintained and sanitized after every trip.</p>
                    <p><b className='font-bold text-black'> Experienced Drivers: </b>  Our drivers are knowledgeable, polite, and prioritize passenger safety.</p>
                    <p><b className='font-bold text-black'> Punctual Service: </b>  Whether it’s a one-way taxi or a round-trip cab, we ensure you reach your destination on time.</p>
                    <p><b className='font-bold text-black'> Hassle-Free Booking: </b> You can easily book your  Vapi To Surat cab online or give us a call for personalized service.</p>

                </div>

                <div className='mt-5 text-black'>
                    <h1 className='font-bold text-black text-lg mb-1'>Contact Hindustan Taxi Service Today!</h1>
                    <p>Ready to experience the best  Vapi To Surat taxi service? Get in touch with Hindustan Taxi Service and book your trip today.</p>
                    <p className='text-black'> <b className='font-bold text-black'> Phone: </b> +91 7600060604</p>
                    <p className='text-black'> <b className='font-bold text-black'> Website: </b> www.hindustantaxiservice.com </p>
                    <p className='text-black'> <b className='font-bold text-black'> Instagram : </b> @hindustan_taxi_service</p>

                </div>

                <p className='mt-5 text-black'>With our affordable pricing, professional drivers, and comfortable vehicles, Hindustan Taxi Service is your best choice for travel between  Vapi To Surat. Book now and enjoy a smooth, safe, and stress-free journey!</p>


                <div className='mt-10 text-black'>
                    <p> Vapi To Surat cab service Swift Dzire or Relevant, Vapi To Surat taxi service Swift Dzire or Relevant, Vapi To Surat one-way taxi Swift Dzire or Relevant, Vapi To Surat one-way cab Swift Dzire or Relevant, Vapi To Surat round-trip cab service Swift Dzire or Relevant, Vapi To Surat round-trip taxi service Swift Dzire or Relevant, Vapi To Surat round-trip taxi Swift Dzire or Relevant, Vapi To Surat round-trip cab Swift Dzire or Relevant, Best taxi service in Vapi for Vapi travel Swift Dzire or Relevant, Vapi To Surat one-way cab from Vapi Swift Dzire or Relevant, Vapi To Surat airport taxi Swift Dzire or Relevant, Vapi To Surat taxi fare Swift Dzire or Relevant, Affordable one-way taxi Swift Dzire or Relevant, Luxury round-trip cab Ertiga or Relevant, SUVs to Sedans and Innova Crysta, Vapi To Surat cab service Toyota Innova Crysta, Vapi To Surat taxi service Toyota Innova Crysta, Vapi To Surat one-way taxi Toyota Innova Crysta, Vapi To Surat one-way cab Toyota Innova Crysta, Vapi To Surat round-trip cab service Toyota Innova Crysta, Vapi To Surat round-trip taxi service Toyota Innova Crysta, Vapi To Surat round-trip taxi Toyota Innova Crysta, Vapi To Surat round-trip cab Toyota Innova Crysta, Best taxi service in Vapi for Vapi travel Toyota Innova Crysta, Vapi To Surat one-way cab from Vapi Toyota Innova Crysta, Vapi To Surat airport taxi Toyota Innova Crysta, Vapi To Surat taxi fare Toyota Innova Crysta, Affordable one-way taxi Toyota Innova Crysta, Luxury round-trip cab Tempo Traveller - 14 seats, SUVs to Sedans and Innova Crysta, Book Vapi To Surat cab online Force Urbania, Vapi To Surat cab booking online Force Urbania, Best one-way cab from Vapi To Surat Force Urbania, Round-trip taxi from Vapi To Surat Force Urbania, Vapi To Surat car hire Force Urbania, Vapi To Surat airport drop taxi Force Urbania</p>
                </div>


            </div>

        </div>


    );
}
