import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { setHours, setMinutes } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS

function RoundTripPage() {
  const editFlag = false;

  const initialMinTime = editFlag
    ? setHours(setMinutes(new Date(), 0), 18)
    : setHours(setMinutes(new Date(), 0), 0);

  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [person, setPerson] = useState('');
  const [pickupDate, setPickupDate] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const [pickupTime, setPickupTime] = useState('');
  const [fromPlace, setFromPlace] = useState(null);
  const [toPlace, setToPlace] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [minTime, setMinTime] = React.useState(initialMinTime);
  const [maxTime, setMaxTime] = React.useState(
    setHours(setMinutes(new Date(), 59), 23)
  );
  const [errors, setErrors] = useState({});

  const fromInputRef = useRef(null);
  const toInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      initPlaceAPI();
    } else {
      loadGoogleMapScript(() => {
        initPlaceAPI();
      });
    }
  }, []);

  const GOOGLE_MAP_API_KEY = 'AIzaSyC34N-enppR8BKspnOpDkV6in2fGsz4drI';

  const loadGoogleMapScript = (callback) => {
    const googleMapScript = document.createElement('script');
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
    document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener('load', callback);
  };

  const initPlaceAPI = () => {
    const autocompleteFrom = new window.google.maps.places.Autocomplete(fromInputRef.current,
      {
        componentRestrictions: { country: 'IN' }, // Restrict search to India
      })

    autocompleteFrom.addListener('place_changed', () => {
      const place = autocompleteFrom.getPlace();
      setFrom(place.formatted_address);
      setFromPlace({
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        name: place.name
      });
    });

    const autocompleteTo = new window.google.maps.places.Autocomplete(toInputRef.current, {
      componentRestrictions: { country: 'IN' }, // Restrict search to India
    })
    autocompleteTo.addListener('place_changed', () => {
      const place = autocompleteTo.getPlace();
      setTo(place.formatted_address);
      setToPlace({
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        name: place.name
      });
    });
  };

  console.log(fromPlace);
  console.log(toPlace);

  const today = new Date().toISOString().split('T')[0];

  const convertTo12HourFormat = (time) => {
    if (!time) return ''; // Handle case where time is null or undefined

    let hours, minutes;

    // Check if time is a Date object
    if (time instanceof Date) {
      hours = time.getHours();
      minutes = time.getMinutes();
    } else {
      // Otherwise, assume time is a string in the format 'HH:MM'
      [hours, minutes] = time.split(':').map(Number);
    }

    const ampm = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  };




  // Validate fields
  const validateFields = () => {
    const newErrors = {};
    if (!from) newErrors.from = 'Pickup location is required.';
    if (!to) newErrors.to = 'Destination location is required.';
    if (!person) newErrors.person = 'Number of persons is required.';
    if (!pickupDate) newErrors.pickupDate = 'Pickup date is required.';
    if (!returnDate) newErrors.returnDate = 'Return date is required.';
    if (!pickupTime) newErrors.pickupTime = 'Pickup time is required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      const formattedTime = convertTo12HourFormat(pickupTime);
      const queryString = new URLSearchParams({
        type: 'roundTrip',
        formattedPickupTime: formattedTime,
        from: encodeURIComponent(from),
        to: encodeURIComponent(to),
        person: encodeURIComponent(person),
        pickupDate: encodeURIComponent(pickupDate),
        returnDate: encodeURIComponent(returnDate),
        pickupTime: encodeURIComponent(pickupTime),
        fromPlace: encodeURIComponent(JSON.stringify(fromPlace)),
        toPlace: encodeURIComponent(JSON.stringify(toPlace))
      }).toString();
      navigate(`/roundtripdetails?${queryString}`);
    }
  };

  return (
    <form onSubmit={handleSubmit} className='h-auto'>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-6 gap-8 px-0 mt-12 mb-5 rounded-[16px]">
        {/* FROM */}
        <div className="relative">
          <input
            type="text"
            placeholder="Start typing city - e.g. Bangalore"
            ref={fromInputRef}
            value={from}
            onChange={(e) => setFrom(e.target.value)}
            className="border-b p-3 w-full bg-white font-normalFont text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
            style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
          />
          <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px] uppercase">Pickup From</label>
          {errors.from && <p className="text-red-500 text-xs mt-1">{errors.from}</p>}
        </div>

        {/* TO */}
        <div className="relative">
          <input
            type="text"
            placeholder="Start typing city - e.g. Mysore"
            ref={toInputRef}
            value={to}
            onChange={(e) => setTo(e.target.value)}
            className="border-b p-3 w-full bg-white font-normalFont text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
            style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
          />
          <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px] uppercase">To Destination</label>
          {errors.to && <p className="text-red-500 text-xs mt-1">{errors.to}</p>}
        </div>

        {/* PERSON */}
        <div className="relative">
          <input
            type="number"
            placeholder="How many persons...?"
            onChange={(e) => setPerson(e.target.value)}
            min="1"
            className="border-b p-3 w-full bg-white font-normalFont text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
            style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
          />
          <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px] uppercase">Person</label>
          {errors.person && <p className="text-red-500 text-xs mt-1">{errors.person}</p>}
        </div>

        {/* PICKUP DATE */}
        <div className="relative font-normalFont">
          <DatePicker
            selected={pickupDate}
            onChange={(date) => setPickupDate(date)} // Set the selected date
            placeholderText="Select a Pickup Date" // Placeholder text for the input field
            dateFormat="MMMM d, yyyy" // Date format (without time)
            className="border-b text-[13px] p-3 w-full font-normalFont bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
            minDate={new Date()} // Disable past dates
          />

          {/* <input
            type="date"
            value={pickupDate}
            onChange={(e) => setPickupDate(e.target.value)}
            className="border-b font-['Open_Sans'] p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
            style={{ fontSize: '13px' }}
            min={today}
          /> */}
          <label  className="absolute top-[-20px] left-0 px-3  py-0 text-customfontBlack font-[700] text-[14px] uppercase">Pickup Date</label>
          {errors.pickupDate && <p className="text-red-500 text-xs mt-1">{errors.pickupDate}</p>}
        </div>

        {/* RETURN DATE */}
        <div className="relative">
          <DatePicker
            selected={returnDate}
            onChange={(date) => setReturnDate(date)} // Set the selected date
            placeholderText="Select a Return Date" // Placeholder text for the input field
            dateFormat="MMMM d, yyyy" // Date format (without time)
            className="border-b text-[13px] p-3 w-full bg-white font-normalFont text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
            minDate={new Date()} // Disable past dates
          />
          {/* <input
            type="date"
            value={returnDate}
            onChange={(e) => setReturnDate(e.target.value)}
            className="border-b font-['Open_Sans'] p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
            style={{ fontSize: '13px' }}
          /> */}
          <label  className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px] uppercase">Return Date</label>
          {errors.returnDate && <p className="text-red-500 text-xs mt-1">{errors.returnDate}</p>}
        </div>

        {/* PICKUP TIME */}
        <div className="relative">
          <DatePicker
            selected={pickupTime}
            onChange={(date) => setPickupTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            minTime={minTime}
            maxTime={maxTime}
            timeCaption="Time"
            dateFormat="h:mm aa"
            className="border-b text-[13px] p-3 w-full bg-white font-normalFont text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
            placeholderText="Select Pickup Time"
          />
          <label  className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px] uppercase">Pickup Time</label>
          {errors.pickupTime && <p className="text-red-500 text-xs mt-1">{errors.pickupTime}</p>}
        </div>
      </div>

      <div className="flex justify-center mt-6">
        
        <button type="submit" className="bg-customBlue text-white w-[100%] sm:w-[30%] py-2 text-md rounded-md font-[700] mt-[-5px] font-nunito">
          Explore Cabs
        </button>
      </div>
    </form>
  );
}

export default RoundTripPage;
