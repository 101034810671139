import React, { useState, useEffect } from 'react';
import { FaBars, FaMobile, FaMobileAlt, FaTimes } from 'react-icons/fa';
import { LuPhone } from "react-icons/lu";
import Logo from '../../Images/logo.png';
import Blog from '../../Images/blog.png';
import Contact_us from '../../Images/contact us.png';
import { Link, Outlet } from 'react-router-dom';
import Route from '../../Images/Location.png';
import Circle from '../../Images/circle.png';
import { Toaster, toast } from 'react-hot-toast'; // Importing Toaster and toast from react-hot-toast


const Header = ({setIsModalVisible}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [userData, setUserData] = useState();

  const handleToggle = () => {
    setIsMobile(!isMobile);
  };

  const closeMobileMenu = () => {
    setIsMobile(false);
  };


  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("isLoggedIn"));
    setUserData(localData);
  }, []);
 
  const [isProfileBoxOpen, setIsProfileBoxOpen] = useState(false);

  const toggleProfileBox = () => {
    setIsProfileBoxOpen(!isProfileBoxOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn'); // Remove login status from localStorage
    setIsModalVisible(true); // Show the login modal again
    toast.success('Logged out successfully!'); // Show success toast
    window.location.reload(); // Refresh the page
  };

  return (
    <>
      <header className="bg-customWhite shadow-md fixed w-full top-0 left-0 z-50 py-1">
        <div className="mx-auto py-2 flex justify-between items-center max-w-[1220px] px-5 h-[65px] ">
          <div className="text-2xl font-bold">
            <h1>
              <Link to='/'>
                {/* <img src={Logo} alt="Logo" className="w-24" /> */}
                <img src={Logo} srcset={Logo} sizes="" alt="Logo" loading="lazy"  className="w-24" />
              </Link>
            </h1>
          </div>

          <div>
            <Link to='tel:+917600060604'>
              <div className="flex items-center text-customBlue bg-customBlue border-[1px] border-customBlue rounded-full">
                <span className={`bg-customWhite rounded-full px-2 py-[2px] hidden md:flex ${isMobile ? 'hidden' : 'flex'}`}>
                  <LuPhone className="mr-1 mt-1 font-[450]" />
                  <span>24x7</span>
                </span>
                <span className="text-customWhite px-3 font-[450]">
                  +91 76000 60604
                </span>
              </div>
            </Link>
          </div>

          <div className="hidden md:flex items-center space-x-6">
            <div className="text-center">
                <Link to='https://play.google.com/store/apps/details?id=com.hindustan.taxiride'>
                <div className="flex flex-col items-center text-gray-800 hover:text-customBlue">
                  <img src={Circle} alt="route" className="mb-1" style={{width:"32px",height:"32px"}} srcset={Circle} sizes="" loading="lazy" />
                  <FaMobileAlt fill='white' className='absolute mt-1' size={22} />
                  <span className="text-[12px]">Download Apps</span>
                </div>
              </Link>
            </div>
            <div className="text-center">
              <Link to='/router'>
                <div className="flex flex-col items-center text-gray-800 hover:text-customBlue">
                  <img src={Route} alt="Routes" className="mb-1" style={{width:"32px",height:"32px"}} srcset={Route} sizes="" loading="lazy" />
                  <span className="text-[12px]">Routes</span>
                </div>
              </Link>
            </div>
            <div className="text-center">
              <Link to='/blog'>
                <div className="flex flex-col items-center text-gray-800 hover:text-customBlue">
                  <img src={Blog} alt="Hindustan Blog" className="mb-1" style={{width:"32px",height:"32px"}}  srcset={Blog} sizes="" loading="lazy"/>
                  <span className="text-[12px]">Hindustan Blog</span>
                </div>
              </Link>
            </div>
            <div className="text-center">
              <Link to='/privacy-policy'>
                <div className="flex flex-col items-center text-gray-800 hover:text-customBlue">
                  <img src={Contact_us} alt="Privacy Policy" className="mb-1" style={{width:"32px",height:"32px"}} srcset={Contact_us} sizes="" loading="lazy" />
                  <span className="text-[12px]">Privacy Policy</span>
                </div>
              </Link>
            </div>
            <div 
  className="text-center relative cursor-pointer"
  onClick={toggleProfileBox} >
  <div className="flex flex-col items-center text-gray-800">
    <img src={Circle} alt="Circle Policy" className="w-8 h-8 mb-1" srcset={Circle} sizes="" loading="lazy" />
    <p className="text-white absolute text-[20px] w-8 h-8 flex items-center justify-center rounded-full">
      {userData?.n[0]?.toUpperCase()}
    </p>
    <span className="text-[12px] capitalize">{userData?.n}</span>
  </div>

  {/* Box that appears on click */}
  {isProfileBoxOpen && (
    <div className="absolute w-52 right-[-60px] top-20 bg-white shadow-lg border rounded-lg p-4 z-50 transform transition-transform duration-300 ease-in-out scale-100">
      <div className="text-center">
        <p className="text-gray-800 font-bold text-lg mb-2">User Profile</p>
        
        <div className="flex items-center justify-center mb-2">
          {/* User Avatar */}
          <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center text-lg font-bold text-gray-600">
            {userData?.n[0]?.toUpperCase()}
          </div>
        </div>

        {/* User Information */}
        <div className="text-sm text-gray-700">
          <p className="mb-1"><b>Name:</b> {userData?.n}</p>
          <p className="mb-1"><b>Phone:</b> {userData?.p}</p>
          <p className="mb-1">{userData?.e}</p>
        </div>

        {/* Interactive Buttons */}
        <div className="mt-4">
          <button className="text-white text-sm bg-customBlue w-full py-1 rounded transition-colors duration-200" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  )}
</div>

          </div>

          <div className="md:hidden" onClick={handleToggle}>
            <button>
              {isMobile ? <FaTimes className="text-2xl text-gray-800" /> : <FaBars className="text-2xl text-gray-800" />}
            </button>
          </div>
        </div>

        <nav className={`md:hidden ${isMobile ? 'block mt-[69px]' : 'hidden'} bg-customWhite w-full absolute  border-b top-0 left-0 z-40`}>
          <div className="flex flex-col py-4 space-y-4 px-5">
            {/* <div className="flex  text-customBlue bg-customBlue border-[1px] border-customBlue rounded-full">
              <span className='bg-customWhite flex rounded-full px-2 py-[2px]'>
                <LuPhone className="mr-1 mt-1 font-[450]" />
                <span>24x7</span>
              </span>
              <Link to='tel:+917600060604'>
                <span className="text-customWhite px-3 font-[450]">+91 76000 60604</span>
              </Link>
            </div> */}
            <Link to='https://play.google.com/store/apps/details?id=com.hindustan.taxiride'>
                <div className="flex items-center gap-3 text-gray-800 hover:text-customBlue">
                  <img src={Circle} alt="route" className="mb-1" style={{width:"32px",height:"32px"}}
                  srcset={Circle} sizes="" loading="lazy"
                   />
                  <FaMobileAlt fill='white' className='absolute ml-[6px] mt-[-4px]' size={20} />
                  <span className="text-[12px] ">Download Apps</span>
                </div>
              </Link>
              <Link to='/router'>
                <div className="flex items-center gap-3 text-gray-800 hover:text-customBlue">
                  <img src={Route} alt="Routes" className="mb-1" style={{width:"32px",height:"32px"}}
                  srcset={Route} sizes="" loading="lazy" />
                  <span className="text-[12px]">Routes</span>
                </div>
              </Link>
            <Link to='/blog'>
              <div className="flex items-center text-gray-800 hover:text-customBlue text-[11px]" onClick={closeMobileMenu}>
                <img src={Blog} alt="Hindustan Blog" className="mr-2" style={{width:"32px",height:"32px"}}
                srcset={Blog} sizes="" loading="lazy" /> Hindustan Blog
              </div>
            </Link>
            <Link to="/privacy-policy">
              <div className="flex items-center text-gray-800 hover:text-customBlue text-[11px]" onClick={closeMobileMenu}>
                <img src={Contact_us} alt="Privacy Policy" className="mr-2" style={{width:"32px",height:"32px"}} srcset={Contact_us} sizes="" loading="lazy" /> Privacy Policy
              </div>
            </Link>
            <div 
  className="text-center relative cursor-pointer"
  onClick={toggleProfileBox} // Toggle on click
>
  <div className="flex flex-row gap-2 items-center text-gray-800">
    <img src={Circle} alt="Circle Policy" className="mb-1" style={{width:"32px",height:"32px"}}
    srcset={Circle} sizes="" loading="lazy" />
    <p className="text-white absolute text-[18px] sm:text-[24px] flex items-center justify-center rounded-full" style={{width:"32px",height:"32px"}}>
      {userData?.n[0]?.toUpperCase()}
    </p>
    <p className="text-[12px] capitalize">{userData?.n}</p>
  </div>

  {/* Box that appears on click */}
  {isProfileBoxOpen && (
    <div className="absolute w-52 right-[-60px] top-20 bg-white shadow-lg border rounded-lg p-4 z-50 transform transition-transform duration-300 ease-in-out scale-100">
      <div className="text-center">
        <p className="text-gray-800 font-bold text-lg mb-2">User Profile</p>
        
        <div className="flex items-center justify-center mb-2">
          {/* User Avatar */}
          <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center text-lg font-bold text-gray-600">
            {userData?.n[0]?.toUpperCase()}
          </div>
        </div>

        {/* User Information */}
        <div className="text-sm text-gray-700">
          <p className="mb-1"><b>Name:</b> {userData?.n}</p>
          <p className="mb-1"><b>Phone:</b> {userData?.p}</p>
          <p className="mb-1">{userData?.e}</p>
        </div>

        {/* Interactive Buttons */}
        <div className="mt-4">
          <button className="text-white text-sm bg-customBlue w-full py-1 rounded transition-colors duration-200" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  )}
</div>

          </div>
        </nav>
      </header>
      <Outlet />
    </>
  );
};

export default Header;
