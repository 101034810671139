import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import check from '../../../Images/check.png';
import close from '../../../Images/close.png';
import Speed from '../../../Images/races.png';
import warning from '../../../Images/war2.png';
import DateImg1 from '../../../Images/date_2.gif';
import Location from '../../../Images/location_gif.gif';
import durationGif from '../../../Images/direction.gif';
import axios from 'axios';
import Time_gif from '../../../Images/time_gif.gif';

const CarDetail = () => {
    const location = useLocation();
    const { car, actualPrice, discountPrice, formData, km, kms, details, type, distancess, date, times } = location.state || {};

    const [data, setData] = useState([]);
    const [error, setError] = useState([]);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [loginData, setLoginData] = useState(null);
    const [inclusionDetails, setInclusionDetails] = useState('');
    const [todos, setTodos] = useState(localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : []);


    const captureRef = useRef(null);
    const img = car ? `https://bhadegadiapi.taxiwalataxi.in/uploads/${car.Image}` : [];
    console.log('img =>', img);
    console.log('formData =>', formData);
    console.log('km =>', kms);
    console.log('details =>', details);
    console.log('distancess =>', distancess);
    console.log('date =>', date);
    const formattedDate = date?.split(' ').slice(1, 4).join(' ');
    console.log("times=====", times);
    useEffect(() => {
        if (car) {
            const fetchData = async () => {
                try {
                    const response = await fetch('https://bhadegadiapi.taxiwalataxi.in/api/v1/getcontact');
                    if (!response.ok) {
                        throw new Error('Network response was not ok ' + response.statusText);
                    }
                    const data = await response.json();
                    setData(data.data.contacts);

                } catch (error) {
                    setError(error.message);
                }
            };
            fetchData();
        }
    }, [car]);


    console.log('car =>', car);
    if (!car) {
        return <div className='text-center mt-10'>No car data available</div>;
    }
    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' }); // Gets the full month name
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
    }

    function generateAndShareImage() {
        const mainDetails =
            `*🚖 Inquiry Confirmation for Your Taxi Booking*\n\n*💰 Total Fare*: ₹${Math.round(actualPrice)}\n*📍 Pickup From*: ${formData.from}\n*📍 Drop To*: ${formData.to}\n*🛣️ Trip Type*: ${formData.type}\n\n*Pick-up Details:*\n*📅 Date*: ${formatDate(formData.pickupDate)}\n*⌚ Time*: ${formData.formattedPickupTime}\n*🚗 Car Type*: ${car.Name}\n\n*✅ Inclusions:* \n${details[0].inclusions.map((item) => {
                const key = Object.keys(item)[0];
                const value = item[key];
                return `• ${value}`;
            }).join('\n')}\n*❌ Exclusions:* \n${details[1].Exclusions.map((item) => {
                return `• ${item}`;
            }).join('\n')}\n\n*📞 Quick Follow-Up:* We will reach out to you shortly via call to discuss your booking and any other inquiries you may have.\n\n🙏 Thank you for choosing Hindustan Taxi Service!\n\n*Hindustan Taxi Service - Surat*\n*+91 76000 60604 | +91 99254 03831*\n*GSTIN :* 24AOOPH2750Q2ZG\n\n*Webiste :https://hindustantaxiservice.com/*\n*App: https://play.google.com/store/apps/details?id=com.hindustan.taxiride*`;
        const phoneNumber = "917600060604";
        const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(mainDetails)}`;
        window.open(whatsappLink, '_blank');
    }

    const storedName = JSON.parse(localStorage.getItem('isLoggedIn'));


    const handlePayment = async () => {
        if (!storedName || !car) {
            console.log('Missing necessary data for payment');
            return;
        }
        const data = {
            user_name: storedName.n,
            email: storedName.e,
            mobileNumber: storedName.p,
            car_name: car.Name,
            amount: Math.round(actualPrice),
        };
        console.log(data);
        try {
            const response = await axios.post('https://details.hindustantaxiservice.com/create-order', data)
            // const response = await axios.post('http://localhost:5000/create-order', data)

            //   console.log(response.data)
            window.location.href = response.data.url
        } catch (error) {
            console.log("error in payment", error)
        }
    }

    return (<>
        <div className=" bg-gray-100  p-6"  >
            <div className='mx-auto'>
                <h1 className='py-5 ml-2 font-[500] text-xl'>{car.Name} Car Details</h1>
                <div className='bg-white mb-5 border rounded-xl'>
                    <div className='grid grid-cols-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-y-5 px-5 py-5'>
                        {/* Booking Type Section */}
                        <div className='col-span-2 py-2'>
                            <div className='flex gap-2'>
                                <img src={Location} className='w-10' alt="Location"  srcset={Location} sizes="" loading="lazy" />
                                <p className='text-sm'>Booking Type</p>
                            </div>

                            <p className='text-sm ml-12 mt-[-10px]'>{type}</p>
                        </div>
                        {/* Pick-up Date Section */}
                        <div className='col-span-2 py-2'>
                            <div className='flex gap-2'>
                                <img src={DateImg1} className='w-10' alt="Pick-up Date"  srcset={DateImg1} sizes="" loading="lazy" />
                                <p className='text-sm'>Pick-up Date</p>
                            </div>
                            <p className='text-sm ml-12 mt-[-17px]'>{formattedDate}</p>
                        </div>
                        {/* Pick-up Time Section */}
                        <div className='col-span-2 py-2'>
                            <div className='flex gap-2'>
                                <img src={Time_gif} className='w-10' alt="Pick-up Time" srcset={Time_gif} sizes="" loading="lazy" />
                                <p className='text-sm'>Pick-up Time</p>
                            </div>
                            <p className='text-sm ml-12 mt-[-15px]'>{times}</p>
                        </div>
                        {/* Duration Section */}
                        <div className='col-span-2 py-2'>
                            <div className='flex gap-2'>
                                <img src={durationGif} className='w-10 mt-1' alt="Pick-up Time"  srcset={durationGif} sizes="" loading="lazy" />
                                <p className='text-sm'>Duration</p>
                            </div>
                            <p className='text-sm ml-12 mt-[-10px]'>{distancess}km</p>
                        </div>
                    </div>
                </div>
                <div className="grid sm:grid-cols-1 lg:grid-cols-8 gap-5">
                    <div className="mx-auto bg-white shadow-lg rounded-lg p-6 lg:col-span-6 sm:col-span-1" >
                        <div className="grid lg:grid-cols-6 md:grid-cols-1 sm:grid-cols-1 sm:gap-5 ">
                            <div className="lg:col-span-2 md:col-span-1 sm:col-span-1 " >
                                <img
                                    src={img}
                                    alt="Car"
                                    className="w-full h-auto rounded-lg shadow-md" />
                            </div>
                            <div className="lg:col-span-4 md:col-span-1  sm:col-span-1 " id='capture2'>
                                <h1 className="text-xl mb-4 font-custom font-bold text-gray-800 my-5 sm:my-5">{car.Name}</h1>
                                <div className="flex items-center mt-2 pb-3 gap-3">
                                    <div className='flex  px-2 py-1 bg-[#F6F7F8] shadow rounded'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" fill="#000"
                                            style={{ width: '13px', height: '13px', marginTop: '3px' }}><path d="M9.572 2.082A1.384 1.384 0 009 3.33v5.897A2.792 2.792 0 0012 12a2.791 2.791 0 003-2.774v-5.86a1.435 1.435 0 00-.608-1.285A4.966 4.966 0 0012 1.5a5.122 5.122 0 00-2.428.582zM4.145 16.865A17.3 17.3 0 0112 15a17.608 17.608 0 017.893 1.867A2 2 0 0121 18.75v.75a3.226 3.226 0 01-3 3H6a3.226 3.226 0 01-3-3v-.75a2.073 2.073 0 011.145-1.885z"></path></svg>
                                        <span className="text-gray-800  text-[13px] font-[600] ml-1" > {car.seats}</span>
                                    </div>
                                    <div className='flex  px-2 py-1 bg-[#F6F7F8] shadow rounded ml-2' >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" style={{ width: '13px', height: '13px', marginTop: '3px' }}><path d="M12 0a1.5 1.5 0 00-1.5 1.5v2.379l-1.94-1.94a1.5 1.5 0 10-2.157 2.085l.037.037 4.06 4.06V10.5H8.121l-4.06-4.06a1.5 1.5 0 00-2.159 2.083l.037.037 1.94 1.94H1.5a1.5 1.5 0 000 3h2.379l-1.94 1.94a1.5 1.5 0 002.122 2.12l4.06-4.06H10.5v2.379l-4.06 4.06a1.5 1.5 0 002.12 2.122l1.94-1.94V22.5a1.5 1.5 0 003 0v-2.379l1.94 1.94a1.5 1.5 0 002.12-2.122l-4.06-4.06V13.5h2.379l4.06 4.06a1.5 1.5 0 002.122-2.12l-1.94-1.94H22.5a1.5 1.5 0 000-3h-2.379l1.94-1.94a1.5 1.5 0 00-2.122-2.12l-4.06 4.06H13.5V8.121l4.06-4.06a1.5 1.5 0 00-2.12-2.122L13.5 3.88V1.5A1.5 1.5 0 0012 0z"></path></svg>
                                        <span className="text-gray-800  text-[13px] font-[600] ml-1"> {car.IsAcCar}</span>
                                    </div>
                                    <div className='flex  px-2 py-1 bg-[#F6F7F8] shadow rounded ml-2' >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" style={{ width: '13px', height: '13px', marginTop: '3px' }}><g clip-path="url(#a)"><path d="M3.75 2.248V12H3a2.25 2.25 0 00-2.25 2.25v6A2.25 2.25 0 003 22.5v.375a1.125 1.125 0 002.25 0V22.5h4.5v.375a1.125 1.125 0 002.25 0V22.5a2.25 2.25 0 002.25-2.25v-6A2.25 2.25 0 0012 12h-.75V2.25A2.25 2.25 0 009 0H6a2.25 2.25 0 00-2.25 2.248zm5.625.002c.207 0 .375.168.375.375V12h-4.5V2.625c0-.207.168-.375.375-.375h3.75zM15.75 13.5a3 3 0 00-2.626-2.977c-.205-.025-.374-.19-.374-.398V2.248A2.25 2.25 0 0115 0h3a2.25 2.25 0 012.25 2.25V6H21a2.25 2.25 0 012.25 2.25v12A2.25 2.25 0 0121 22.5v.375a1.125 1.125 0 01-2.25 0V22.5h-2.625a.375.375 0 01-.375-.375V13.5zm2.625-11.25h-3.75a.375.375 0 00-.375.375V6h4.5V2.625a.375.375 0 00-.375-.375z"></path></g></svg>
                                        <span className="text-gray-800 text-[13px] font-[600] ml-1"> {car.BootSpace}</span>
                                    </div>
                                    <div className='flex  px-2 py-1 bg-[#F6F7F8] shadow rounded ml-2' >
                                        <img src={Speed} className='w-4 h-4'  srcset={Speed} sizes="" loading="lazy" alt='img' />
                                        <span className="text-gray-800  text-[13px] font-[600] ml-2"> {car.Type}</span>
                                    </div>
                                </div>
                                <div className="grid lg:grid-cols-4 sm:grid-cols-1 gap-6 sm:gap-5">
                                    <div className='bg-[#F6F7F8] w-full lg:col-span-1 sm:col-span-1 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 py-2'>
                                        <h2 className="text-[16px] font-semibold text-gray-800 text-center my-2">Inclusions</h2>
                                        <ul className="">
                                            {details[0].inclusions.map((item, index) => {
                                                const key = Object.keys(item)[0];
                                                const value = item[key];
                                                return (
                                                    <li
                                                        key={index}
                                                        className='flex items-center space-x-2 px-2 rounded-md '
                                                    >
                                                        <img src={check} className="w-4 h-4" alt="Check"  srcset={check} sizes="" loading="lazy" />
                                                        <span className='text-[12px] font-medium text-gray-700 py-1'>{value}</span>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                    <div className='bg-[#F6F7F8] lg:col-span-3 sm:col-span-1 rounded-lg shadow-md py-2'>
                                        <h2 className="text-[16px]  font-semibold text-gray-800 text-center my-2">Exclusions</h2>
                                        <div className="grid sm:grid-cols-1 lg:grid-cols-2 ">
                                            {details[1].Exclusions.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className='flex items-center space-x-2 px-2  rounded-md'>
                                                    <img src={close} className="w-4 h-4" alt="Close"  srcset={close} sizes="" loading="lazy" />
                                                    <span className='text-[12px] font-medium text-gray-700 py-1'>{item}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5 bg-[#F6F7F8] rounded-lg shadow-md py-2'>
                            <h2 className="text-[16px]  font-semibold text-gray-800 my-2 px-2">Terms and conditions</h2>
                            <ul className='mt-2 px-2'>
                                {details[2]['Terms and conditions'].map((item, index) => (
                                    <li key={index} className='flex items-center space-x-3'>
                                        <img src={warning} className="w-5 h-5" alt="warning"  srcset={warning} sizes="" loading="lazy"/>
                                        <span className='text-[14px] font-medium text-gray-700'>{item} </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <hr className='my-5' />
                        {data.length > 0 && (
                            <div className="">
                                <h2 className="text-xl font-bold mb-2 text-customBlue font-custom">Contact Details</h2>
                                {data.map((item, index) => (
                                    <div key={index} className="mb-4 p-4 border border-gray-300 rounded-lg shadow-sm leading-6">
                                        <p className="text-[14px] font-semibold text-customBlack font-regularcustom ">{item.cmpName}</p>
                                        <p className="text-[14px] text-gray-700 font-[500]">{item.phoneno1} &nbsp; | &nbsp; {item.phoneno2}</p>
                                        <p className="text-[14px] text-gray-600 font-[500]">{item.address}</p>
                                        <p className="text-[14px] text-gray-600 font-[500]">GSTIN: 24A00PH2750Q2ZG</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="bg-red-200 p-2 rounded-xl">
                            <p className="text-red-600 rounded text-[13.5px] font-regularcustom font-[600] px-4">
                                Note:  This is not a confirmed booking, this is only for quotation rates. Please send an inquiry for final booking. Rates may vary.
                            </p>
                        </div>
                        <div className='flex gap-5'>

                        </div>
                    </div>
                    <div className='lg:col-span-2 sm:col-span-1 ' id="user-details">
                        <div className='bg-white shadow-lg rounded-lg mb-4 p-6'>

                            <div className='mb-4'>
                                <h1 className='text-lg font-[600] mb-2 text-gray-800 font-custom'>Pick-up Details</h1>
                                <span className='text-sm text-gray-700'>{formatDate(formData.pickupDate)}</span> &nbsp;
                                <span className='text-sm text-gray-700'>{formData.formattedPickupTime}</span>
                                <p className='text-sm text-gray-700'>{formData.from}</p>
                            </div>
                            <div>
                                <h1 className='text-lg font-[600] mb-2 text-gray-800 font-custom'>Drop-off Details</h1>
                                <span className='text-sm text-gray-700'>{formatDate(formData.pickupDate)}</span>
                                <p className='text-sm text-gray-700'>{formData.to}</p>
                            </div>
                        </div>
                        <div className='bg-white rounded-lg shadow-lg p-6'>
                            <h1 className='text-lg font-semibold mb-4 text-gray-800 font-custom'>Price Summary</h1>
                            <div className='flex justify-between items-center mb-1'>
                                <p className='text-sm text-gray-700 font-[600]'>Car Rental Fee</p>
                                <p className='text-green-600 text-xl text-gray-700 font-[700]'>₹ <del>{Math.round(discountPrice)}</del></p>
                            </div>

                            <p className='text-gray-600 text-[12px]'>
                                Taxes Include {formData.type} (pick-up in {formData.to}, Customer facilities, tourism tax)
                            </p>
                            <div className='flex justify-between items-center'>
                                <p className='text-sm text-gray-700 font-[600]'>Discount</p>
                                <p className='text-xl font-bold text-red-600 font-[700]'>₹ {Math.round((discountPrice - actualPrice))}</p>
                            </div>
                            <hr className='my-2' />
                            <div className='flex justify-between items-center mb-4'>
                                <p className='text-lg font-bold'>Total Amount</p>
                                <p className='text-xl font-[900] text-blue-600'>₹ {Math.round(actualPrice)}</p>
                            </div>
                            {/* <button className='w-full text-sm bg-customBlue text-gray-800 shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)] py-2 rounded-lg font-semibold hover:bg-customBlueDark transition duration-300' onClick={generateAndShareImage} >
                                Send Inquiry
                            </button>
                            <button className='w-full text-sm bg-customBlue text-gray-800 shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)] py-2 rounded-lg font-semibold hover:bg-customBlueDark transition duration-300 mt-5' onClick={handlePayment} >
                                Proceed to pay
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='fixed bottom-0 w-full flex gap-20 py-5 shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)] bg-gray-200 justify-center items-center'>
            <button className='w-[30%] buynow text-sm bg-[#FF9800] text-gray-800 shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)] py-2.5 rounded-lg font-semibold hover:bg-black hover:text-white transition duration-300 ' onClick={generateAndShareImage} >
                Send Inquiry
            </button>
           
             
            <button className='w-[30%] buynow text-sm bg-[#FF9800] text-gray-800 shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)] py-2.5 rounded-lg font-semibold hover:bg-black hover:text-white transition duration-300' onClick={handlePayment} >
                Proceed to pay
            </button>
        </div>
    </>

    );
}
export default CarDetail;


// import React, { useEffect, useState } from 'react';
// function MyComponent() {
//   const [name, setName] = useState('');
//   useEffect(() => {
//     const storedName = localStorage.getItem('isLoggedIn');
//     console.log(storedName)
//     if (storedName) {
//       setName(storedName);
//     }
//   }, []);
//   return (
//     <div>
//       <h1>Hello, {name}!</h1>
//     </div>
//   );
// }
// export default MyComponent;