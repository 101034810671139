import { Helmet } from 'react-helmet'; 

const SEO = ({ title, description, keywords, noindex }) => {
    console.log("title", "description", "keywords", title, description, keywords,  "noindex", noindex);
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        {/* {canonical && <link rel="canonical" href={canonical} />} */}
        {!noindex && <meta name="robots" content="index, follow" />}
      </Helmet>
    );
  };
  
  export default SEO;
  