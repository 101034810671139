// Footer.jsx
import React from 'react';
import { FaFacebook, FaInstagram, FaPhone, FaWhatsapp } from 'react-icons/fa';
import { FaLocationArrow } from 'react-icons/fa';
import { FaAddressBook } from 'react-icons/fa';
import logo from '../../Images/logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-8 bottom-0">
      <div className="container mx-auto px-4">
        
        <div className='flex gap-10'>
          <h1 className='text-[25px] pb-4 font-[500]'> Hindustan Taxi Services </h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
          <div>
            <h1 className="text-md font-bold text-customBlue"> COMPANY </h1>
            {/* <p className="mt-2">About Us</p> */}
            <p className="mt-2 text-sm"> 
              <Link to='/blog'> <a className='text-white'> Blogs </a> </Link>  </p>
            <p className="mt-2 text-sm" > 
            <Link to='/privacy-policy'>  <a className='text-white'> Privacy Policy </a> </Link>  </p>
            <p className="mt-2 text-sm" > 
            <Link to='/refund-policy'>  <a className='text-white'> Refund Policy </a> </Link>  </p>
            <p className="mt-2 text-sm" > 
            <Link to='/terms-condition'> <a className='text-white'> Terms & Conditions </a> </Link>  </p>
            <p className="mt-2 text-sm" > 
            <Link to='/contactus'> <a  className='text-white'> Contact Us </a> </Link>  </p>
            <p className="mt-2 text-sm" > 
            <Link to='/aboutus'> <a  className='text-white'> About Us </a> </Link> </p>




            {/* <p className="mt-2">Terms & Conditions</p> */}
            {/* <p className="mt-2">Refunds</p> */}
          </div>
          <div>
            <h1 className="text-lg font-bold text-customBlue"> SERVICES </h1>
            <p className="mt-2 text-sm"> <Link to='/'> <a className='text-white'> Local Car Rentals </a> </Link>  </p>
            <p className="mt-2 text-sm"> <Link to='/'> <a className='text-white'> Outstation Taxi </a> </Link>  </p>
            <p className="mt-2 text-sm"><Link to='/'> <a className='text-white'> One Way Cabs </a> </Link>  </p>
            <p className="mt-2 text-sm"><Link to='/'> <a className='text-white'> Airport Taxi </a> </Link>  </p>
            <p className="mt-2 text-sm"><Link to='https://play.google.com/store/apps/details?id=com.hindustan.taxiride' target='_blank'> Download App  </Link> </p>
            <p className="mt-2 text-sm"><Link to='/router'> <a className='text-white'> Routes </a> </Link>  </p>
          </div>
          <div>
            <h1 className="text-lg font-bold text-customBlue"> GET IN TOUCH </h1>
            <Link to='tel:+917600060604'> <a className="mt-2 text-left flex items-center text-white">
              <FaPhone size={18} className="mr-4" /> +91 76000 60604 
            </a> </Link>
            <p className="mt-2 text-sm text-left flex">
              <FaAddressBook size={18} className='mr-4' /> contact@hindustantaxiservice.com</p>
            <p className="mt-2 text-sm text-left flex">
              <FaLocationArrow size={18} className='mr-4' /> F-22, Swastik Plaza, Yogichowk, Surat, Gujarat - 395010</p>
            <div className="flex gap-5 my-4 ml-1">
              <Link to='https://www.instagram.com/hindustan_taxi_service/'  target='_blank'> <div className='bg-white hover:bg-customBlue p-1 rounded-md cursor-pointer'>
                <FaInstagram size={22} className='text-black ' />
              </div> </Link>
              {/* <div className='bg-customBlue hover:bg-white p-1 rounded-md cursor-pointer'>
                <FaFacebook size={22} className='text-black ' />
              </div> */}
              <Link to='https://api.whatsapp.com/send?phone=917600060604' target='_blank'>
               <div className='bg-white hover:bg-customBlue p-1 rounded-md cursor-pointer'>
                <FaWhatsapp size={22} className='text-black ' />
              </div>
              </Link>
            </div>
          </div>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
