import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // If using React Router

export default function ScrollToTop() {
  const location = useLocation(); // Optional, for React Router

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]); // Add location to the dependency array if using React Router

  // Rest of your component logic
}