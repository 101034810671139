import React from 'react'
import { Link } from 'react-router-dom'

export default function Refund_policy() {
  return (
    <div class="bg-gray-100 font-sans leading-normal tracking-normal">
    <div class="container mx-auto py-12 px-6">
        <h1 class="text-3xl font-bold text-gray-800 mb-6">Terms & Conditions</h1>
        <p class="mb-4 text-gray-700">
            Welcome to Hindustan Taxi Service. These Terms & Conditions govern your use of our website and services. By accessing or using our services, you agree to the terms outlined below. Please read these carefully before making any bookings or using our services.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4"> General Terms</h2>
        <p class="mb-4 text-gray-700">
            These Terms & Conditions apply to all users of Hindustan Taxi Service, including those who browse our website or book rides through our platform. By accessing our services, you agree to these terms. Hindustan Taxi Service reserves the right to modify, update, or change these terms at any time without prior notice. It is your responsibility to regularly review these terms.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4"> User Responsibilities</h2>
        <p class="mb-4 text-gray-700">
            As a user, you are responsible for maintaining the confidentiality of your account information, including passwords. You agree to use our services for lawful purposes only and refrain from engaging in any activity that could harm, disrupt, or violate the rights of Hindustan Taxi Service or other users.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4"> Booking and Payment</h2>
        <p class="mb-4 text-gray-700">
            All bookings must be made through our official website, mobile app, or customer service number. Payment is required at the time of booking and can be made using credit cards, debit cards, online wallets, or other accepted methods. Hindustan Taxi Service reserves the right to cancel or reject bookings due to insufficient funds, fraudulent activity, or any violation of these Terms & Conditions.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4"> Service Confirmation</h2>
        <p class="mb-4 text-gray-700">
            Upon successful booking, you will receive a confirmation via email or SMS with the details of your ride. It is your responsibility to ensure that the provided information (e.g., pickup location, destination, time) is accurate. Hindustan Taxi Service will not be liable for any issues resulting from incorrect booking information.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4"> Cancellations and Refunds</h2>
        <p class="mb-4 text-gray-700">
            Customers can cancel bookings up to 24 hours before the scheduled pickup time without any charge. Cancellations made within 24 hours of the pickup time may be subject to a cancellation fee. Please refer to our <Link to='/refund-policy'> <a class="text-blue-500 hover:underline">Refund Policy</a> </Link> for more details. Refunds, if applicable, will be processed to the original payment method within 7-10 business days.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4"> No-Show Policy</h2>
        <p class="mb-4 text-gray-700">
            If you fail to show up at the designated pickup location within 15 minutes of the scheduled time, it will be considered a "no-show." In such cases, no refunds will be provided, and Hindustan Taxi Service reserves the right to charge a no-show fee.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4"> Service Availability</h2>
        <p class="mb-4 text-gray-700">
            Hindustan Taxi Service strives to offer reliable and punctual service. However, we cannot guarantee service availability at all times due to factors beyond our control, such as weather, traffic, vehicle availability, or mechanical issues. In case of delays or cancellations caused by unforeseen circumstances, we will notify you as soon as possible.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4"> Passenger Conduct and Safety</h2>
        <p class="mb-4 text-gray-700">
            Passengers are expected to behave courteously and respectfully towards the driver and other passengers. Any abusive, inappropriate, or illegal behavior will not be tolerated, and the driver has the right to terminate the trip immediately. Passengers must wear seat belts and follow all safety instructions provided by the driver.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4"> Luggage and Personal Belongings</h2>
        <p class="mb-4 text-gray-700">
            Passengers are responsible for their own luggage and personal belongings. Hindustan Taxi Service is not liable for any loss, damage, or theft of items left in the vehicle during or after the ride. It is recommended not to leave valuables unattended in the vehicle.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4"> Child Safety and Special Needs</h2>
        <p class="mb-4 text-gray-700">
            For the safety of young passengers, parents or guardians are required to provide and install child safety seats when traveling with infants or young children. If you require special assistance or have any special needs (e.g., wheelchair access), please inform us at the time of booking, and we will do our best to accommodate your request.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4"> Vehicle Condition and Driver Responsibility</h2>
        <p class="mb-4 text-gray-700">
            All our vehicles are regularly maintained to ensure safety and comfort. However, Hindustan Taxi Service is not responsible for delays or cancellations due to mechanical failures or accidents. Our drivers are experienced and licensed professionals who follow local traffic laws and prioritize your safety. In the event of an emergency, the driver has the authority to make decisions to ensure passenger safety.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4"> Liability and Indemnity</h2>
        <p class="mb-4 text-gray-700">
            Hindustan Taxi Service will not be liable for any direct, indirect, incidental, or consequential damages resulting from the use of our services, including but not limited to accidents, delays, personal injury, or loss of belongings. By using our services, you agree to indemnify and hold Hindustan Taxi Service harmless from any claims or damages resulting from your use of the service.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4"> Privacy Policy</h2>
        <p class="mb-4 text-gray-700">
            Hindustan Taxi Service is committed to protecting your personal information. Please refer to our <Link to='/privacy-policy' > <a class="text-blue-500 hover:underline">Privacy Policy</a> </Link> to understand how we collect, use, and safeguard your data.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4"> Governing Law and Jurisdiction</h2>
        <p class="mb-4 text-gray-700">
            These Terms & Conditions are governed by and construed in accordance with the laws of India. Any disputes arising out of or related to these terms shall be resolved in the courts of [Your City], India.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4"> Contact Us</h2>
        <p class="mb-4 text-gray-700">
            If you have any questions or concerns about these Terms & Conditions, please contact us:
        </p>
        <ul class="list-disc pl-6 text-gray-700">
            <li class="mb-2 text-black-500 font-bold">Phone: 
                <Link to="tel:+917600060604" > <a class="text-black-500 "> +91 76000 60604 </a> </Link> </li>
            <li class="mb-2 text-black-500 font-bold">Email: 
            <Link to="mailto:support@hindustantaxiservice.com" >   <a class="text-black-500 "> support@hindustantaxiservice.com </a> </Link> </li>
        </ul>
    </div>
</div>
  )
}
