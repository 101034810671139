import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos"; // Import AOS library
import '../../App.css';
import Drive from '../../Images/car-parts-01.svg';
import KM from '../../Images/car-parts-02.svg';
import petrol from '../../Images/car-parts-03.svg';
import basic from '../../Images/car-parts-04 (1).svg';
import pessenger from '../../Images/car-seat.png';
import bag from '../../Images/bag.png';

import dudhni from '../../Images/dudhni.png';
import dwarka from '../../Images/dwarka.png';
import goa from '../../Images/goa.png';
import lonavala from '../../Images/lonavala.png';
import panchgani from '../../Images/panchgani.png';
import saputara from '../../Images/saputara.png';
import sasangir from '../../Images/sasangir.png';
import somnath from '../../Images/somnath.png';
import statu_of_unity from '../../Images/statu_of_unity.png';

const Sliders = () => {
  const [data, setData] = useState([]);

  const sliderData = [
    { image: dudhni },
    { image: dwarka },
    { image: goa },
    { image: lonavala },
    { image: panchgani },
    { image: saputara },
    { image: sasangir },
    { image: somnath },
    { image: statu_of_unity }
  ];

  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); // Initialize AOS with duration and once option
    fatchData(); // Fetch data on mount
  }, []); // `useEffect` without condition

  // Fetch data function
  const fatchData = async () => {
    try {
      const addRecordEndpoint = 'https://hapi.hindustanrides.in/api/v1/getCarCabDetail';
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await fetch(addRecordEndpoint, options);
      const jsonResponse = await response.json();
      setData(jsonResponse?.result);
    } catch (err) {
      console.log(err);
    }
  };

  if (!Array.isArray(sliderData) || sliderData.length <= 0) {
    return null;
  }

  // Arrow components
  const NextArrow = ({ onClick }) => (
    <div className="slick-next" onClick={onClick}>
      <i className="fa fa-chevron-right" aria-hidden="true"></i>
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div className="slick-prev" onClick={onClick}>
      <i className="fa fa-chevron-left" aria-hidden="true"></i>
    </div>
  );

  // Slider settings with responsiveness and arrows
  const settings = {
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dots: true,
    swipeToSlide: true,
    infinite: true,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleBooking = (item) => {
    window.location.href = `https://api.whatsapp.com/send?phone=917600060604&text=I am interested to book ${item?.carcab_name} from Hindustan Rides.`;
  };

  return (
    <>
      <center className="smsm:mt-[28rem] minsm:mt-[28rem] 
      maxsm:mt-[28rem] sm:mt-[20rem] md:mt-[20rem] lg:mt-[20rem] xl:mt-[14rem]">
        <h1 className='font-[600] text-[26px] text-customfontBlack fade-in'>
          Explore Most Popular Cars
        </h1>
        <p className='font-[550] text-[20px] text-customfontBlack fade-in'>
          Choose Our Cab Collections
        </p>
      </center>

      <div className="flex justify-center items-center mx-4 mt-2">
        <Slider {...settings} className="w-full max-w-[1200px] mt-8">
          {data.map((item) => (
            <div key={item.id} className="my-1 px-3 mx-auto mt-10">
              <article className="overflow-hidden shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
                <a className="relative inline-block duration-300 ease-in-out w-full">
                  <div className="shadow p-2 rounded-lg bg-white">
                    <div className="flex justify-center relative rounded-lg overflow-hidden ">
                      <div className="transition-transform duration-500 w-full">
                        <div className="absolute inset-0 bg-black opacity-10"></div>
                        <img src={`https://hapi.hindustanrides.in/uploads/${item.carcabImg}`} alt={item.carcab_name} />
                      </div>
                    </div>
                    <div className="text-left justify-left bg-[#fff]">
                      <div className="justify-left">
                        <p className="text-[18px] pt-4 ml-2">{item.carcab_name}</p>
                        <div className="flex items-center ml-2">
                          <span className="text-customBlue text-xl">★★★★☆</span>
                        </div>
                        <div className="grid grid-cols-3 text-center mt-3">
                          <div className="border p-2">
                            <img src={Drive} className="w-[20px] mx-auto" alt="Auto" />
                            <p className="ml-1 text-[13px] py-1">Auto</p>
                          </div>
                          <div className="border p-2">
                            <img src={KM} className="w-[20px] mx-auto" alt="KM" />
                            <p className="ml-1 text-[13px] py-1">{item.bus_rate}</p>
                          </div>
                          <div className="border p-2">
                            <img src={petrol} className="w-[20px] mx-auto" alt="Petrol" />
                            <p className="ml-1 text-[13px] py-1">Petrol</p>
                          </div>
                        </div>
                        <div className="grid grid-cols-3 text-center">
                          <div className="border p-2">
                            <img src={basic} className="w-[20px] mx-auto" alt="Basic" />
                            <p className="ml-1 text-[13px] py-1">Basic</p>
                          </div>
                          <div className="border p-2">
                            <img src={pessenger} className="w-[20px] mx-auto" alt="Seats" />
                            <p className="ml-1 text-[13px] py-1">{item.pessenger} Seats</p>
                          </div>
                          <div className="border p-2">
                            <img src={bag} className="w-[20px] mx-auto" alt="Bags" />
                            <p className="ml-1 text-[13px] py-1">{item.luggage} Bags</p>
                          </div>
                        </div>
                        <div>
                          <p className="ml-2 text-[12px] py-3">{item.extra}</p>
                        </div>
                      </div>
                      <div className="flex items-center justify-center">
                        <button
                          className="uppercase bg-customBlue w-full py-2 mt-2 font-[600] text-sm rounded text-white"
                          
                        >
                          Book now
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </article>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default Sliders;
