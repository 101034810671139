// import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';

// function TenHoursDetails() {
//   const [formData, setFormData] = useState({});
//   const [distance, setDistance] = useState({});
//   const [selectCarDetails, setSelectCarDetails] = useState([]);
//   const location = useLocation();

//   useEffect(() => {
//     // Extract the query parameters from the URL
//     const queryParams = new URLSearchParams(location.search);

//     // Decode and parse the data
//     const type = queryParams.get('type');
//     const hourType = decodeURIComponent(queryParams.get('hourType'));
//     const from = decodeURIComponent(queryParams.get('from'));
//     const person = decodeURIComponent(queryParams.get('person'));
//     const pickupDate = decodeURIComponent(queryParams.get('pickupDate'));
//     const pickupTime = decodeURIComponent(queryParams.get('pickupTime'));

//     // Set the form data in state
//     setFormData({
//       type,
//       hourType,
//       from,
//       person,
//       pickupDate,
//       pickupTime
//     });
//   }, [location.search]);

//   // Fetch car details when formData.type changes
//   useEffect(() => {
//     if (formData.type) {
        
//       const apiUrl = `https://bhadegadiapi.taxiwalataxi.in/api/v1/searchalldata?type=${formData.hourType}&seats=${formData.person}`;

//       const fetchCarDetails = async () => {
//         try {
//           const response = await fetch(apiUrl, {
//             method: 'GET',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//           });

//           // Check if the response is successful
//           if (!response.ok) {
//             throw new Error('Network response was not ok');
//           }

//           // Parse the JSON response
//           const data = await response.json();
//           console.log(data);
          
//           setSelectCarDetails(data?.data?.taxiDetails || []);
//         } catch (error) {
//           console.error('Error:', error);
//         }
//       };
//       fetchCarDetails();
//     }
//   }, [formData.type]);
// console.log(selectCarDetails);

//   // Fetch distance when formData.from and formData.to change
//   useEffect(() => {
//     if (formData.from && formData.to) {
//       const apiUrl = 'https://bhadegadiapi.taxiwalataxi.in/api/v1/adddistance';
//       const postData = {
//         cityname1: formData.from,
//         cityname2: formData.to
//       };

//       const fetchDistance = async () => {
//         try {
//           const response = await fetch(apiUrl, {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(postData),
//           });

//           // Check if the response is successful
//           if (!response.ok) {
//             throw new Error('Network response was not ok');
//           }

//           // Parse the JSON response
//           const data = await response.json();
//           setDistance(data || {});
//         } catch (error) {
//           console.error('Error:', error);
//         }
//       };
//       fetchDistance();
//     }
//   }, [formData.from, formData.to]);

//   return (
//     <div>
//       <h1>10101010101010 One Way Car Details</h1>
//       {/* Render formData and other details */}
//       <div>
//         <p><strong>Type:</strong> {formData.type}</p>
//         <p><strong>Hour Type:</strong> {formData.hourType}</p>
//         <p><strong>From:</strong> {formData.from}</p>
//         <p><strong>Person:</strong> {formData.person}</p>
//         <p><strong>Pickup Date:</strong> {formData.pickupDate}</p>
//         <p><strong>Pickup Time:</strong> {formData.pickupTime}</p>
//       </div>

//       {/* Display car details */}
//       <div>
//         <h2>Available Cars</h2>
//         {selectCarDetails.length > 0 ? (
//           <ul>
//             {selectCarDetails.map((car, index) => (
//               <li key={index} className="car-item">
//                 <h3>{car.carName}</h3>
//                 <img src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${car.Image}`} width='200px' alt={car.carName} className="car-image" />
//                 <p>Name : {car.Name}</p>
//                 <p><strong>Type:</strong> {car.Type}</p>
//                 <p><strong>Seats:</strong> {car.seats}</p>
//                 <p><strong>actualRateFix:</strong> {car.tenHours?.actualRateFix}</p>
//                 <p><strong>bookingKMLimit:</strong> {car.tenHours?.bookingKMLimit}</p>
//                 <p><strong>discountRateFix:</strong> {car.tenHours?.discountRateFix}</p>
//                 <p><strong>IsAcCar:</strong> {car.IsAcCar}</p>
//                 <p><strong>Boot Space:</strong> {car.bootspace} liters</p>
//               </li>
//             ))}
//           </ul>
//         ) : (
//           <p>No car details available.</p>
//         )}
//       </div>
      
//       {/* Display distance if available */}
//       <div>
//         <h2>Distance</h2>
//         {distance ? (
//           <p><strong>Distance between {formData.from} and {formData.to}:</strong> {distance.distance} km</p>
//         ) : (
//           <p>No distance information available.</p>
//         )}
//       </div>
//     </div>
//   );
// }

// export default TenHoursDetails;


import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GoogleMap,  Marker } from '@react-google-maps/api';
import axios from 'axios';
import check from '../../../../Images/check.png';
import close from '../../../../Images/close.png';
import warning from '../../../../Images/war2.png';


function TenHoursDetails() {
  const [formData, setFormData] = useState({});
  const [distance, setDistance] = useState({});
  const [selectCarDetails, setSelectCarDetails] = useState([]);
  const [position, setPosition] = useState(null);
  const [error, setError] = useState(null);
  const location = useLocation();
  const API_KEY = 'AIzaSyC34N-enppR8BKspnOpDkV6in2fGsz4drI';
  const navigate = useNavigate();


  const containerStyle = {
    height: "100%",
    width: "100%",
  };
  
  useEffect(() => {
    console.log('useEffect triggered');
    const queryParams = new URLSearchParams(location.search);
  
    const type = queryParams.get('type');
    const hourType = decodeURIComponent(queryParams.get('hourType'));
    const from = decodeURIComponent(queryParams.get('from'));
    const person = decodeURIComponent(queryParams.get('person'));
    const formattedPickupTime = decodeURIComponent(queryParams.get('formattedPickupTime'));
  
    const pickupDate = decodeURIComponent(queryParams.get('pickupDate'));
    const pickupTime = decodeURIComponent(queryParams.get('pickupTime'));
  
    setFormData({
      type,
      formattedPickupTime,
      hourType,
      from,
      person,
      pickupDate,
      pickupTime
    });
  }, [location.search]);
  
  useEffect(() => {
    if (formData.type) {

      const apiUrl = `https://bhadegadiapi.taxiwalataxi.in/api/v1/searchalldata?type=${formData.hourType}&seats=${formData.person}`;

      const fetchCarDetails = async () => {
        try {
          const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          // Check if the response is successful
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          // Parse the JSON response
          const data = await response.json();

          setSelectCarDetails(data?.data?.taxiDetails || []);
        } catch (error) {
          console.error('Error:', error);
        }
      };
      fetchCarDetails();
    }
  }, [formData.type]);
  console.log(selectCarDetails);
  
console.log('formData =>',formData);

useEffect(() => {
  const fetchCoordinates = async () => {
    console.log('formData_function =>', formData);
    try {
      const address = formData.from;
      console.log('address =>', address);
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${API_KEY}`
      );
      if (response.data.results.length > 0) {
        const { lat, lng } = response.data.results[0].geometry.location;
        setPosition({ lat, lng });
      } else {
        console.error('Location not found');
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  if (formData.from) {
    fetchCoordinates();
  }
}, [formData]);  


const handleViewDealClick = (car) => {
  console.log('car =>',car);
 const actualPrice = car.tenHours.actualRateFix
 const discountPrice = car.tenHours.discountRateFix
 const km = car.tenHours.perKMRateOneway
 const distancess = car.tenHours.bookingKMLimit
 const hr = Math.round(car.tenHours.actualRateFix / 10)
 const type = 'Local - 10 Hours';
 const date = formData.pickupDate;
 const times = formData.formattedPickupTime;


 const details = [
  {
    'inclusions': [{ '0': 'Fuel Charges' }]
  },
  {
    'Exclusions': [`Pay ₹${km}/km after ${distancess}km`, 
      `Pay ₹${hr}/hr after 10 hours`, 
      'Toll / State tax', 
      'Night Allowance',
      'Parking',
    'Driver Allowance']
  },
  {
    'Terms and conditions': [
      'Your Trip has a KM limit as well as Hours limit. If your usage exceeds these limits, you will be charged for the excess KM and/or Hours used.',
      'The KM and Hours usage will be calculated starting from your pick-up point and back to the pick-up point. ',
      'The Airport entry charge, if applicable, is not included in the fare and will be charged extra.',
      'All road toll fees, parking charges, state taxes etc. are charged extra and need to be paid to the concerned authorities as per actuals.',
      'For driving between 09:45 PM to 06:00 AM on any of the nights, an additional allowance will be applicable and is to be paid to the driver.'

    ]
  }
];
  navigate(`/car-booking/${car._id}`, { 
    state: { 
      car,
      formData,
      actualPrice,
      discountPrice,
      details,
      type,
      distancess, date, times
    }
  });
};
  return (
    <div>
      <div className=" mx-auto px-4 py-8">
      <div className='bg-white  border border-gray-200 shadow-lg container mx-auto rounded-2xl p-4'>
          <div className='grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-6 gap-4'>
            {/* Booking Type */}
            <div className=''>
              <div className='flex items-center'>
                {/* Icon can be added here */}
                <h1 className='text-md font-semibold text-gray-600'>Booking Type</h1>
              </div>
              <h1 className='text-sm font-bold text-customBlue'> Ten Hours Trip</h1>
            </div>

            {/* Pickup Date */}
            <div className=''>
              <div className='flex items-center gap-2'>
                {/* Icon can be added here */}
                <p className='text-md font-semibold text-gray-600'>Pick-up Date</p>
              </div>
              <p className='text-sm font-bold text-customBlue'>
                {new Date(formData.pickupDate).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </p>
            </div>

            {/* Pickup Time */}
            <div className=''>
              <div className='flex items-center gap-2'>
                {/* Icon can be added here */}
                <p className='text-md font-semibold text-gray-600'>Pick-up Time</p>
              </div>
              <p className='text-sm font-bold text-customBlue'>{formData.formattedPickupTime}</p>
            </div>

            <div className='col-span-2'>
              <div className='flex items-center gap-2'>
                {/* Icon can be added here */}
                <p className='text-md font-semibold text-gray-600'>Pickup-from</p>
              </div>
              <p className='text-sm font-bold text-customBlue'>{formData.from}</p>
            </div>
          </div>

        </div>
      </div>


      <div className='grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-10 px-2 sm:px-10'>
        <div className="">
          <h2 className="text-lg font-semibold mb-4">Available Cars</h2>
          {selectCarDetails.length > 0 ? (
            selectCarDetails.map((car, index) => {

              return <>
                <div key={index} className="mx-auto my-8  rounded-[10px] shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
                <div className="grid smmin:grid-cols-3 sm:grid-cols-7 smmin:gap-0 sm:gap-4">
                    <div className='p-4  smmin:col-span-1 sm:col-span-3 '>
                      <div>
                        <h2 className="text-lg font-bold pb-3">{car.Name}</h2>
                        <div className="flex items-center mt-2 pb-3">
                          <div className='flex bg-[#DBD8D4] px-2 py-1 rounded'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" fill="#000"
                              style={{ width: '1rem', height: '1rem', marginTop: '3px' }}><path d="M9.572 2.082A1.384 1.384 0 009 3.33v5.897A2.792 2.792 0 0012 12a2.791 2.791 0 003-2.774v-5.86a1.435 1.435 0 00-.608-1.285A4.966 4.966 0 0012 1.5a5.122 5.122 0 00-2.428.582zM4.145 16.865A17.3 17.3 0 0112 15a17.608 17.608 0 017.893 1.867A2 2 0 0121 18.75v.75a3.226 3.226 0 01-3 3H6a3.226 3.226 0 01-3-3v-.75a2.073 2.073 0 011.145-1.885z"></path></svg>
                            <span className="text-black text-[13px] font-[600] ml-1" > {car.seats}</span>
                          </div>
                          <div className='flex bg-[#DBD8D4] px-2 py-1 rounded ml-2' >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" style={{ width: '1rem', height: '1rem', marginTop: '3px' }}><path d="M12 0a1.5 1.5 0 00-1.5 1.5v2.379l-1.94-1.94a1.5 1.5 0 10-2.157 2.085l.037.037 4.06 4.06V10.5H8.121l-4.06-4.06a1.5 1.5 0 00-2.159 2.083l.037.037 1.94 1.94H1.5a1.5 1.5 0 000 3h2.379l-1.94 1.94a1.5 1.5 0 002.122 2.12l4.06-4.06H10.5v2.379l-4.06 4.06a1.5 1.5 0 002.12 2.122l1.94-1.94V22.5a1.5 1.5 0 003 0v-2.379l1.94 1.94a1.5 1.5 0 002.12-2.122l-4.06-4.06V13.5h2.379l4.06 4.06a1.5 1.5 0 002.122-2.12l-1.94-1.94H22.5a1.5 1.5 0 000-3h-2.379l1.94-1.94a1.5 1.5 0 00-2.122-2.12l-4.06 4.06H13.5V8.121l4.06-4.06a1.5 1.5 0 00-2.12-2.122L13.5 3.88V1.5A1.5 1.5 0 0012 0z"></path></svg>
                            <span className="text-black text-[13px] font-[600] ml-1"> {car.IsAcCar}</span>
                          </div>
                          <div className='flex bg-[#DBD8D4] px-2 py-1 rounded ml-2' >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" style={{ width: '1rem', height: '1rem', marginTop: '3px' }}><g clip-path="url(#a)"><path d="M3.75 2.248V12H3a2.25 2.25 0 00-2.25 2.25v6A2.25 2.25 0 003 22.5v.375a1.125 1.125 0 002.25 0V22.5h4.5v.375a1.125 1.125 0 002.25 0V22.5a2.25 2.25 0 002.25-2.25v-6A2.25 2.25 0 0012 12h-.75V2.25A2.25 2.25 0 009 0H6a2.25 2.25 0 00-2.25 2.248zm5.625.002c.207 0 .375.168.375.375V12h-4.5V2.625c0-.207.168-.375.375-.375h3.75zM15.75 13.5a3 3 0 00-2.626-2.977c-.205-.025-.374-.19-.374-.398V2.248A2.25 2.25 0 0115 0h3a2.25 2.25 0 012.25 2.25V6H21a2.25 2.25 0 012.25 2.25v12A2.25 2.25 0 0121 22.5v.375a1.125 1.125 0 01-2.25 0V22.5h-2.625a.375.375 0 01-.375-.375V13.5zm2.625-11.25h-3.75a.375.375 0 00-.375.375V6h4.5V2.625a.375.375 0 00-.375-.375z"></path></g></svg>
                            <span className="text-black text-[13px] font-[600] ml-1"> {car.BootSpace}</span>
                          </div>
                        </div>

                        <div className="flex items-center">
                        {/* <p><strong>actualRateFix:</strong> {car.tenHours.actualRateFix}</p>
                <p><strong>bookingKMLimit:</strong> {car.tenHours.bookingKMLimit}</p>
                <p><strong>discountRateFix:</strong> {car.tenHours.discountRateFix}</p> */}
                          <span className="text-yellow-600 font-[600] text-sm">up to {car.tenHours.bookingKMLimit} km</span>
                          <span className="ml-2 text-gray-500 font-[600] text-sm">Good deal</span>
                        </div>
                      </div>

                      <div className="mt-2 text-gray-500">
                        <span className='font-[600] text-sm'>All Inclusive</span>
                      </div>
                    </div>

                    <div className="relative mr-2  smmin:col-span-1 sm:col-span-2">
                      <div>
                        <img src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${car.Image}`} alt="Jeep Compass" className="w-full h-full mt-6 "  srcset={`https://bhadegadiapi.taxiwalataxi.in/uploads/${car.Image}`} sizes="" loading="lazy" />
                      </div>
                    </div>

                    <div className="border-l smmin:col-span-1 sm:col-span-2  border-gray-400 bg-white  justify-between rounded-r-[16px] p-4 h-full" style={{ flexFlow: 'wrap'}}>
                      <div className='text-center'>
                        <div className="text-xl font-bold text-gray-800 py-1">

                        ₹ {car.tenHours.actualRateFix}
                        </div>
                        <div className='py-1'>
                          <del className="text-md font-semibold text-red-500"> ₹ {car.tenHours.discountRateFix}</del>
                        </div>
                      </div>
                      <p className='text-center font-bold text-green-700 text-sm'>Toll-Tax Inclusion</p>
                      {/* <button className="self-center mt-auto bg-customBlue mt-[-10px] font-[600] text-white py-1 px-4 text-[15px] w-full rounded">View Deal</button> */}
                      <button onClick={() => handleViewDealClick(car)} className="self-center mt-auto bg-customBlue mt-[10px] font-[600] text-white py-1 px-4 text-[14px] w-full rounded">View Deal</button>
                    </div>

                  </div>
                </div>
              </>
            }
            )
          ) : (
            <p>No car details available.</p>
          )}
        </div>
        <div>
        <h1 className='text-xl font-semibold'>Direction</h1>
        <div className='bg-white rounded-lg p-6 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] h-[445px] mt-7' >
        {position ? (
    
          <GoogleMap
          googleMapsApiKey="AIzaSyC34N-enppR8BKspnOpDkV6in2fGsz4drI"
            mapContainerStyle={containerStyle}
            center={position}
            zoom={10}
          >
            <Marker position={position} label="Surat" />
          </GoogleMap>
       
      ) : (
        <p>Loading map...</p>
      )}
        </div>
        </div>
      </div>
     
    </div>
  );
}

export default TenHoursDetails;