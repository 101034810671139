import React from 'react'
import { Link } from 'react-router-dom'

export default function Refund_policy() {
  return (
    <div class="bg-gray-100 font-sans leading-normal tracking-normal">
    <div class="container mx-auto py-12 px-6">
        <h1 class="text-3xl font-bold text-gray-800 mb-6">Refund Policy</h1>
        <p class="mb-4 text-gray-700">
            Thank you for choosing Hindustan Taxi Service. We strive to provide you with reliable and efficient transportation services. Please read our refund policy carefully before making any bookings. By accessing our services, you agree to the terms outlined below.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">No Refunds Policy</h2>
        <p class="mb-4 text-gray-700">
            The following terms apply to any services you have booked with us.
        </p>
        <p class="mb-4 text-gray-700">
            Once a booking has been made and confirmed for our taxi services, no refunds will be provided under any circumstances. This policy is in place because we allocate resources such as vehicles and drivers based on confirmed bookings, and late cancellations may affect our ability to serve other customers.
        </p>
        <p class="mb-4 text-gray-700">
            Refunds will not be granted for missed rides due to customer delays, incorrect details provided at the time of booking, or any changes made after confirmation of the booking. We recommend double-checking your details before submitting a booking request.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Cancellation of Orders</h2>
        <p class="mb-4 text-gray-700">
            Cancellations are allowed up to 24 hours before the scheduled pickup time. However, once the booking is within 24 hours of the pickup time, no cancellations or refunds will be entertained. Please review your booking carefully before confirming your reservation.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Exchanges</h2>
        <p class="mb-4 text-gray-700">
            We do not offer exchanges or modifications to bookings once confirmed. If you need to change your booking, please cancel the existing booking and create a new one.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Contact Us</h2>
        <p class="mb-4 text-gray-700">
            If you have any questions or concerns regarding our refund policy, please feel free to contact us using the information below:
        </p>
        <ul class="list-disc pl-6 text-gray-700">
            <li class="mb-2 text-black-500 font-bold">Phone: 
                <Link to='tel:+917600060604'> <a class="text-black-500 "> +91 76000 60604 </a> </Link> </li>
            <li class="mb-2 text-black-500 font-bold">Email: 
            <Link to='mailto:support@hindustantaxiservice.com'> <a class="text-black-500 "> support@hindustantaxiservice.com </a> </Link></li>
        </ul>
    </div>
</div>
  )
}
