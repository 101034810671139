// import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';

// function ToDetails() {
//   const [hourType, setHourType] = useState('eightHours');
//   const [from, setFrom] = useState('');
//   const [to, setTo] = useState('');
//   const [person, setPerson] = useState('');
//   const [pickupDate, setPickupDate] = useState('');
//   const [pickupTime, setPickupTime] = useState('');
//   const [loadMap, setLoadMap] = useState(false);
//   const [fromPlace, setFromPlace] = useState(null);
//   const [toPlace, setToPlace] = useState(null);
//   const fromInputRef = useRef(null);
//   const toInputRef = useRef(null);

//   const navigate = useNavigate();

//   useEffect(() => {
//     if (window.google && window.google.maps && window.google.maps.places) {
//       initPlaceAPI();
//     } else {
//       loadGoogleMapScript(() => {
//         setLoadMap(true);
//         initPlaceAPI();
//       });
//     }
//   }, []);

//   // API key of the Google Map
//   const GOOGLE_MAP_API_KEY = 'AIzaSyC34N-enppR8BKspnOpDkV6in2fGsz4drI';

//   // Load Google Maps JavaScript API
//   const loadGoogleMapScript = (callback) => {
//     const googleMapScript = document.createElement('script');
//     googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
//     document.body.appendChild(googleMapScript);
//     googleMapScript.addEventListener('load', callback);
//   };

//   // Initialize the Google Places Autocomplete
//   const initPlaceAPI = () => {
//     const options = {
//       types: ['airport'] // Restrict results to airports
//     };

//     const autocompleteFrom = new window.google.maps.places.Autocomplete(fromInputRef.current, options);
//     autocompleteFrom.addListener('place_changed', () => {
//       const place = autocompleteFrom.getPlace();
//       setFrom(place.formatted_address);
//       setFromPlace({
//         address: place.formatted_address,
//         lat: place.geometry.location.lat(),
//         lng: place.geometry.location.lng(),
//         name: place.name
//       });
//     });

//     const autocompleteFrom2 = new window.google.maps.places.Autocomplete(toInputRef.current, options);
//     autocompleteFrom2.addListener('place_changed', () => {
//       const place = autocompleteFrom2.getPlace();
//       setTo(place.formatted_address);
//       setToPlace({
//         address: place.formatted_address,
//         lat: place.geometry.location.lat(),
//         lng: place.geometry.location.lng(),
//         name: place.name
//       });
//     });
//   };

//   console.log(fromPlace);

//   const today = new Date().toISOString().split('T')[0];

//   // Handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (!from || !person || !pickupDate || !pickupTime) {
//       alert('Please fill in all required fields.');
//       return;
//     }

//     // Create a data object based on the current hourType
//     const formData = {
//       from: from,
//       person: person,
//       pickupDate: pickupDate,
//       pickupTime: pickupTime,
//     };

//     // Print form data to the console
//     console.log('Form Data:', formData);

//     // Construct query string
//     const queryString = new URLSearchParams({
//       type: 'airportTrip',
//       from: encodeURIComponent(from),
//       person: encodeURIComponent(person),
//       pickupDate: encodeURIComponent(pickupDate),
//       pickupTime: encodeURIComponent(pickupTime),
//     }).toString();

//     // Navigate to the new route with the query string
//     navigate(`/airportHrsdetails?${queryString}`);
//   };


//   return (
//     <>
//         <form onSubmit={handleSubmit}>
//           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-8 px-4 mt-12 mb-5 rounded-[16px]">
//             {/* From Airport */}
//             <div className="relative">
//               <input
//                 type="text"
//                 placeholder="Start typing city - e.g. Bangalore"
//                 ref={fromInputRef}
//                 value={from}
//                 onChange={(e) => setFrom(e.target.value)}
//                 className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
//                 style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
//               />
//               <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">From Airport</label>
//             </div>

//             <div className="relative">
//           <input
//             type="text"
//             placeholder="Start typing city - e.g. Mysore"
//             ref={toInputRef}
//             value={to}
//             onChange={(e) => setTo(e.target.value)}
//             className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
//             style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
//           />
//           <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px] uppercase">To Destination</label>
//         </div>

//             {/* Person */}
//             <div className="relative">
//               <input
//                 type="number"
//                 placeholder="How many person...?"
//                 value={person}
//                 onChange={(e) => setPerson(e.target.value)}
//                 className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
//                 style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
//               />
//               <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PERSON</label>
//             </div>

//             {/* Pickup Date */}
//             <div className="relative">
//               <input
//                 type="date"
//                 value={pickupDate}
//                 onChange={(e) => setPickupDate(e.target.value)}
//                 className="border-b font-['Open_Sans'] p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
//                 style={{ fontSize: '13px' }}
//               />
//               <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PICK UP DATE</label>
//             </div>

//             {/* Pickup Time */}
//             <div className="relative">
//               <input
//                 type="time"
//                 value={pickupTime}
//                 onChange={(e) => setPickupTime(e.target.value)}
//                 className="border-b p-3 font-['Open_Sans'] w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
//                 style={{ fontSize: '12px', '--placeholder-font-size': '15px' }}
//               />
//               <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PICK UP AT TIME</label>
//             </div>
//           </div>
//           <div className="flex justify-center mt-6">
//             <button type="submit" className="bg-customBlue text-customfontBlack absolute py-3 px-16 text-lg rounded-md font-[700] mt-[-5px] font-nunito">
//               EXPLORE CABS
//             </button>
//           </div>
//         </form>
     
//     </>
//   );
// }

// export default ToDetails;



import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 
import { setHours, setMinutes, format } from 'date-fns'; 

function ToDetails() {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [person, setPerson] = useState('');
  const [pickupDate, setPickupDate] = useState('');
  const [pickupTime, setPickupTime] = useState('');
  const [loadMap, setLoadMap] = useState(false);// Set minimum time 
  const [errors, setErrors] = useState({}); // To store validation errors
  const fromInputRef = useRef(null);
  const toInputRef = useRef(null);
  const [startDate, setStartDate] = useState('');
  const editFlag = false;

  const initialMinTime = editFlag
  ? setHours(setMinutes(new Date(), 0), 18)
  : setHours(setMinutes(new Date(), 0), 0);
  const [minTime, setMinTime] = useState(initialMinTime); // Set minimum time 

  const navigate = useNavigate();
  const GOOGLE_MAP_API_KEY = 'AIzaSyC34N-enppR8BKspnOpDkV6in2fGsz4drI';

  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      initPlaceAPI();
    } else {
      loadGoogleMapScript(() => {
        // setLoadMap(true);
        initPlaceAPI();
      });
    }
  }, []);

  const loadGoogleMapScript = (callback) => {
    if (document.querySelector(`script[src*="maps.googleapis.com"]`)) {
      callback();
      return;
    }
    const googleMapScript = document.createElement('script');
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
    
    document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener('load', callback);
  };

  const initPlaceAPI = () => {

    const options = {
      types: ['airport'],
      componentRestrictions: { country: 'IN' }// Restrict results to airports
    };

    const optionss = {
      componentRestrictions: { country: 'IN' }// Restrict results to airports
    };


    const autocompleteFrom = new window.google.maps.places.Autocomplete(fromInputRef.current, optionss);
    autocompleteFrom.addListener('place_changed', () => {
      const place = autocompleteFrom.getPlace();
      setFrom(place.formatted_address || place.name); // Use formatted_address if available, else use name
    });

    const autocompleteTo = new window.google.maps.places.Autocomplete(toInputRef.current, options);
    autocompleteTo.addListener('place_changed', () => {
      const place = autocompleteTo.getPlace();
      setTo(place.formatted_address || place.name); // Use formatted_address if available, else use name
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};

    
    if (!from) validationErrors.from = 'From Airport is required.';
    if (!to) validationErrors.to = 'Destination is required.';
    if (!person) validationErrors.person = 'Please specify the number of persons.';
    if (!pickupDate) validationErrors.pickupDate = 'Pick up date is required.';
    if (!startDate) validationErrors.pickupTime = 'Pick up time is required.';

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = {
      from,
      to,
      person,
      pickupDate,
      pickupTime: startDate,
    };

    console.log('Form Data:', formData);
    const formattedPickupTime = format(startDate, 'h:mm aa');

    const queryString = new URLSearchParams({
      type: 'airportTrip',
      from: encodeURIComponent(from),
      to: encodeURIComponent(to),
      person: encodeURIComponent(person),
      pickupDate: encodeURIComponent(pickupDate),
      pickupTime: encodeURIComponent(formattedPickupTime)
  }).toString();

    navigate(`/airportHrsdetails?${queryString}`);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-5 gap-8 px-0 mt-12 mb-5 rounded-[16px]">
        {/* From Airport */}
        <div className="relative">
          <input
            type="text"
            placeholder="Start typing city - e.g. Bangalore"
            ref={fromInputRef}
            value={from}
            onChange={(e) => setFrom(e.target.value)}
            className="border-b p-3 w-full font-normalFont bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
            style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
          />
          <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px] uppercase">From Airport</label>
          {errors.from && <p className="text-red-500 text-xs mt-1">{errors.from}</p>}
        </div>

        {/* To Destination */}
        <div className="relative">
          <input
            type="text"
            placeholder="Start typing city - e.g. Mysore"
            ref={toInputRef}
            value={to}
            onChange={(e) => setTo(e.target.value)}
            className="border-b p-3 w-full bg-white font-normalFont text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
            style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
          />
          <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px] uppercase ">To Destination</label>
          {errors.to && <p className="text-red-500 text-xs mt-1">{errors.to}</p>}
        </div>

        {/* Person */}
        <div className="relative">
          <input
            type="number"
            placeholder="How many person...?"
            value={person}
            onChange={(e) => setPerson(e.target.value)}
            className="border-b p-3 w-full bg-white font-normalFont text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
            style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
          />
          <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px] uppercase">Person</label>
          {errors.person && <p className="text-red-500 text-xs mt-1">{errors.person}</p>}
        </div>

        {/* Pickup Date */}
        <div className="relative">
        <DatePicker
              selected={pickupDate}
              onChange={(date) => setPickupDate(date)} // Set the selected date
              placeholderText="Select Pickup Date" // Placeholder text for the input field
              dateFormat="MMMM d, yyyy" // Date format (without time)
              className="border-b text-[13px] p-3 w-full font-normalFont bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
              minDate={new Date()} // Disable past dates
            />
        
          {/* <input
            type="date"
            value={pickupDate}
            onChange={(e) => setPickupDate(e.target.value)}
            className="border-b font-['Open_Sans'] p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
            style={{ fontSize: '13px' }}
          /> */}
          <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px] uppercase">Pick Up Date</label>
          {errors.pickupDate && <p className="text-red-500 text-xs mt-1">{errors.pickupDate}</p>}
        </div>

        {/* Pickup Time */}
        <div className="relative">
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              if (date.getDate() === new Date().getDate()) {
                setMinTime(setHours(setMinutes(new Date(), 0), 18)); // Set minimum time to 6 PM if the current day is selected
              } else {
                setMinTime(setHours(setMinutes(new Date(), 0), 0)); // Reset minimum time to 12 AM
              }
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            minTime={minTime}
            maxTime={setHours(setMinutes(new Date(), 0), 23)} // Limit max time to 11 PM
            placeholderText="Select Pickup Time" // Added placeholder text
            dateFormat="h:mm aa" // Display format for time
            className="border-b text-[13px] p-3 w-full font-normalFont bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
          />
          <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px] uppercase">Pickup Time</label>
          {errors.pickupTime && <p className="text-red-500 text-xs mt-1">{errors.pickupTime}</p>}
        </div>
      
      </div>
      <div className="flex justify-center mt-6">
      <button type="submit" className="bg-customBlue text-white w-[100%] sm:w-[30%] py-2 text-md rounded-md font-[700] mt-[-5px] font-nunito">
          Explore Cabs
        </button>
      </div>
    </form>
  );
}

export default ToDetails;
